import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { getCookie } from './common';
import { USER_ID } from '@/constants';

export const trackEvent = async (event: EventName, data: any = {}) => {
  if (typeof window.gtag === 'undefined') {
    await initGA();
  }
  const currentLocale = localStorage.getItem('currentLocale') || '';
  const currentLang = localStorage.getItem('currentLang') || '';
  const eventCount = sessionStorage.getItem(EVENT_COUNT)
    ? Number(sessionStorage.getItem(EVENT_COUNT)) + 1
    : 1;
  const userID = getCookie(USER_ID);
  gtag('event', event, {
    ...data,
    eventCount,
    sessionID: window?.gaGlobal?.vid?.split('.')[1],
    url: window?.location?.href,
    deviceType: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
      ? 'Mobile'
      : /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(
          navigator.userAgent,
        )
      ? 'Tablet'
      : 'Desktop',
    language: currentLocale,
    lang: currentLocale,
    userID: userID,
  });
  gtag('set', 'language', currentLang);

  sessionStorage.setItem(EVENT_COUNT, String(eventCount));
};

export const initGA = async () => {
  if (typeof window.gtag !== 'undefined') {
    return;
  }
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    //@ts-ignore
    dataLayer.push(arguments);
  }
  //@ts-ignore
  window.gtag = gtag;
  await initUserID();
  trackEvent('Launch');
};

const initUserID = async () => {
  const currentLocale = localStorage.getItem('currentLocale') || '';
  let userUniqueId = localStorage.getItem('user_finger_print');
  let userUniqueIdTime = localStorage.getItem('uer_finger_print_stime');
  if (
    !userUniqueId ||
    Date.now() - Number(userUniqueIdTime) > 15 * 24 * 60 * 60 * 1000
  ) {
    const fp = await FingerprintJS.load();
    const result = await fp.get();

    userUniqueId = result.visitorId;
    localStorage.setItem('user_finger_print', userUniqueId);
    localStorage.setItem('uer_finger_print_stime', String(Date.now()));
  }
  //@ts-ignore
  gtag('js', new Date());

  //@ts-ignore
  gtag('config', GA_CODE, {
    user_id: localStorage.getItem('user_finger_print'),
    user_unique_id: localStorage.getItem('user_finger_print'),
    device: navigator.userAgent,
    language: currentLocale,
    lang: currentLocale,
    debug_mode: true,
  });
};

type EventName =
  | 'Launch'
  | 'icar_page_view'
  | 'request_quote_CTA'
  | 'submit_quote'
  | 'full_name_field'
  | 'phone_num_field'
  | 'email_field'
  | 'type_of_insurance_field'
  | 'vehicle_brand_field'
  | 'yom_field'
  | 'dont_know_button_click'
  | 'Privacy_notice_&_policy_button_click'
  | 'contact_us_button_click'
  | 'FAQ_Section';
