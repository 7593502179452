import { AUTO_FUN, ONE_CAR, AUTO_SPIN } from './index';
import autoFunFrame from '@/assets/desktop/autoFun/Frame.svg';
import smallAutoFunFrame from '@/assets/mobile/autoFun/Frame.svg';
import autofunIglooIcon from '@/assets/desktop/autoFun/Logo.svg';
import smallAutoFunIglooIcon from '@/assets/mobile/autoFun/Logo.svg';
import autofun from '@/assets/desktop/autoFun/logo_autofun_slogan.svg';
import smallAutoFun from '@/assets/mobile/autoFun/logo_autofun_slogan.svg';
import oneCarFrame from '@/assets/desktop/one2car/Frame.svg';
import oneCarIglooLogo from '@/assets/desktop/one2car/Logo.svg';
import oneCar from '@/assets/desktop/one2car/LOGO - one2car 1.svg';
import smallOneCarFrame from '@/assets/mobile/one2Car/Frame.svg';
import smallOneCarIglooLogo from '@/assets/mobile/one2Car/Logo.svg';
import smallOneCar from '@/assets/mobile/one2Car/LOGO - one2car 1.svg';
import autoSpinFrame from '@/assets/desktop/autoSpin/Frame.svg';
import autoSpinIglooLogo from '@/assets/desktop/autoSpin/Logo.svg';
import autoSpin from '@/assets/desktop/autoSpin/Autospinn 1.svg';
import smallAutoSpinFrame from '@/assets/mobile/autoSpin/Frame.svg';
import smallAutoSpinIglooLogo from '@/assets/mobile/autoSpin/Logo.svg';
import smallAutoSpin from '@/assets/mobile/autoSpin/Autospinn 1.svg';
import autoFunFooterLogo from '@/assets/desktop/autoFun/FooterIglooLogo.svg';
import smallAutoFunFooterLogo from '@/assets/mobile/autoFun/FooterIglooMobile.svg';
import oneCarFooterLogo from '@/assets/desktop/one2car/FooterLogo.svg';
import smallOneCarFooterLogo from '@/assets/mobile/one2Car/FooterLogoMobile.svg';
import autoSpinFooterLogo from '@/assets/desktop/autoSpin/FooterLogo.svg';
import smallAutoSpinFooterLogo from '@/assets/mobile/autoSpin/FooterLogoMobile.svg';
import autofunBenefitIcon1 from '@/assets/desktop/autoFun/benefitIcon1.svg';
import autofunBenefitIcon2 from '@/assets/desktop/autoFun/benefitIcon2.svg';
import autofunBenefitIcon3 from '@/assets/desktop/autoFun/benefitIcon3.svg';
import smallAutoFunBenefitIcon1 from '@/assets/mobile/autoFun/benefitIcon1.svg';
import smallAutoFunBenefitIcon2 from '@/assets/mobile/autoFun/benefitIcon2.svg';
import smallAutoFunBenefitIcon3 from '@/assets/mobile/autoFun/benefitIcon3.svg';
import autospinBenefitIcon1 from '@/assets/desktop/autoSpin/benefitIcon1.svg';
import autospinBenefitIcon2 from '@/assets/desktop/autoSpin/benefitIcon2.svg';
import autospinBenefitIcon3 from '@/assets/desktop/autoSpin/benefitIcon3.svg';
import smallAutospinBenefitIcon1 from '@/assets/mobile/autoSpin/benefitIcon1.svg';
import smallAutospinBenefitIcon2 from '@/assets/mobile/autoSpin/benefitIcon2.svg';
import smallAutospinBenefitIcon3 from '@/assets/mobile/autoSpin/benefitIcon3.svg';
import onecarBenefitIcon1 from '@/assets/desktop/one2car/benefitIcon1.svg';
import onecarBenefitIcon2 from '@/assets/desktop/one2car/benefitIcon2.svg';
import onecarBenefitIcon3 from '@/assets/desktop/one2car/benefitIcon3.svg';
import smallOnecarBenefitIcon1 from '@/assets/mobile/one2Car/benefitIcon1.svg';
import smallOnecarBenefitIcon2 from '@/assets/mobile/one2Car/benefitIcon2.svg';
import smallOnecarBenefitIcon3 from '@/assets/mobile/one2Car/benefitIcon3.svg';
import insurer1 from '@/assets/desktop/LMG.png';
import insurer2 from '@/assets/desktop/MSIG.png';
import insurer3 from '@/assets/desktop/TokioMarine.png';
import insurer4 from '@/assets/desktop/Jaymart.png';
import insurer5 from '@/assets/desktop/AIA.png';
import smallInsurer1 from '@/assets/mobile/LMG.png';
import smallInsurer2 from '@/assets/mobile/MSIG.png';
import smallInsurer3 from '@/assets/mobile/TokioMarine.png';
import smallInsurer4 from '@/assets/mobile/Jaymart.png';
import smallInsurer5 from '@/assets/mobile/AIA.png';
import closeAutoFun from '@/assets/desktop/autoFun/closeIcon.svg';
import closeAutoSpin from '@/assets/desktop/autoSpin/closeIcon.svg';
import closeOneCar from '@/assets/desktop/one2car/closeIcon.svg';
import mobileCloseAutoFun from '@/assets/mobile/autoFun/closeIcon.svg';
import mobileCloseAutoSpin from '@/assets/mobile/autoSpin/closeIcon.svg';
import mobileCloseOneCar from '@/assets/mobile/one2Car/closeIcon.svg';

export const AUTO_FUN_MOBILE_LOGOS = {
  logo: smallAutoFun,
  frame: smallAutoFunFrame,
  igloo: smallAutoFunIglooIcon,
  footerLogo: smallAutoFunFooterLogo,
};

export const AUTO_FUN_DESKTOP_LOGOS = {
  logo: autofun,
  frame: autoFunFrame,
  igloo: autofunIglooIcon,
  footerLogo: autoFunFooterLogo,
};

export const ONE_2_CAR_MOBILE_LOGOS = {
  logo: smallOneCar,
  frame: smallOneCarFrame,
  igloo: smallOneCarIglooLogo,
  footerLogo: smallOneCarFooterLogo,
};

export const ONE_2_CAR_DESKTOP_LOGOS = {
  logo: oneCar,
  frame: oneCarFrame,
  igloo: oneCarIglooLogo,
  footerLogo: oneCarFooterLogo,
};

export const AUTO_SPIN_MOBILE_LOGOS = {
  logo: smallAutoSpin,
  frame: smallAutoSpinFrame,
  igloo: smallAutoSpinIglooLogo,
  footerLogo: smallAutoSpinFooterLogo,
};

export const AUTO_SPIN_DESKTOP_LOGOS = {
  logo: autoSpin,
  frame: autoSpinFrame,
  igloo: autoSpinIglooLogo,
  footerLogo: autoSpinFooterLogo,
};

export const AUTOFUN_STYLES = {
  headerStyles: {
    backgroundColor: '#FFF',
    buttonBackground: '#1E1E1E',
    buttonColor: '#FFC400',
  },
  footerStyles: {
    backgroundColor: '#1E1E1E',
    color: '#FFF',
  },
};

export const ONE_CAR_STYLES = {
  headerStyles: {
    backgroundColor: '#FA0102',
    buttonBackground: '#FFD800',
    buttonColor: '#212E3A',
  },
  footerStyles: {
    backgroundColor: '#FFF8CE',
    color: '#212E3A',
  },
};

export const AUTO_SPIN_STYLES = {
  headerStyles: {
    backgroundColor: '#003880',
    buttonBackground: '#FF5F00',
    buttonColor: '#FFF',
  },
  footerStyles: {
    backgroundColor: '#151B22',
    color: '#FFF',
  },
};

export const BENEFIT_ICONS = {
  autofun: {
    icon1: {
      mobile: smallAutoFunBenefitIcon1,
      desktop: autofunBenefitIcon1,
    },
    icon2: {
      mobile: smallAutoFunBenefitIcon2,
      desktop: autofunBenefitIcon2,
    },
    icon3: {
      mobile: smallAutoFunBenefitIcon3,
      desktop: autofunBenefitIcon3,
    },
  },
  autospinn: {
    icon1: {
      mobile: smallAutospinBenefitIcon1,
      desktop: autospinBenefitIcon1,
    },
    icon2: {
      mobile: smallAutospinBenefitIcon2,
      desktop: autospinBenefitIcon2,
    },
    icon3: {
      mobile: smallAutospinBenefitIcon3,
      desktop: autospinBenefitIcon3,
    },
  },
  one2car: {
    icon1: {
      mobile: smallOnecarBenefitIcon1,
      desktop: onecarBenefitIcon1,
    },
    icon2: {
      mobile: smallOnecarBenefitIcon2,
      desktop: onecarBenefitIcon2,
    },
    icon3: {
      mobile: smallOnecarBenefitIcon3,
      desktop: onecarBenefitIcon3,
    },
  },
};

export const INSURER_MOBILE = [
  smallInsurer1,
  smallInsurer2,
  smallInsurer3,
  smallInsurer4,
  smallInsurer5,
];

export const INSURER_DESKTOP = [
  insurer1,
  insurer2,
  insurer3,
  insurer4,
  insurer5,
];

export const CLOSE_ICON_DESKTOP = {
  autofun: closeAutoFun,
  autospinn: closeAutoSpin,
  one2car: closeOneCar,
};

export const CLOSE_ICON_MOBILE = {
  autofun: mobileCloseAutoFun,
  autospinn: mobileCloseAutoSpin,
  one2car: mobileCloseOneCar,
};

export const BENEFIT_SOURCE: { [key: string]: string } = {
  [AUTO_FUN]: 'AutoFun',
  [ONE_CAR]: 'One2Car',
  [AUTO_SPIN]: 'AutoSpinn',
};
