import { Drawer } from 'antd';
import styles from './index.less';
import Success from '@/assets/desktop/success.svg';
import { useIntl } from 'react-intl';
import { Dispatch, SetStateAction } from 'react';

const SuccessDrawer: React.FC<{
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  closeIcon: string;
}> = ({ openModal, setOpenModal, closeIcon }) => {
  const { formatMessage } = useIntl();
  return (
    <Drawer
      open={openModal}
      placement={'bottom'}
      className={styles.drawerComponent}
      onClose={() => setOpenModal(false)}
      closeIcon={<img src={closeIcon} alt="close icon" />}
    >
      <div className={styles.messageContent}>
        <div className={styles.headerThank}>
          <img
            src={Success}
            alt=""
            className={styles.img}
            height="30px"
            width="30px"
          />
          <div className={styles.header}>
            {formatMessage({ id: `Thank you for submitting the form` })}
          </div>
          <div className={styles.description}>
            {formatMessage({
              id: `Our team will contact you back within 1 working day`,
            })}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default SuccessDrawer;
