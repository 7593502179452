import React from 'react';
import styles from './index.less';
import { Button } from 'iglooform';
import { Header } from 'typings';
import { useIntl } from 'react-intl';
import { trackEvent } from '@/utils/ga';
import { getGASource } from '@/utils/common';

const HeaderContent: React.FC<Header> = ({ logos, headerTheme, source }) => {
  const { formatMessage, locale } = useIntl();

  const handleRequestQuote = () => {
    const targetSection = document.getElementById('quoteForm');
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }

    trackEvent('request_quote_CTA', {
      Site: getGASource(source, locale),
      button_placement: 'navbar',
    });
  };

  return (
    <div
      className={styles.content}
      style={{ background: headerTheme?.backgroundColor }}
    >
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src={logos.logo} alt="" />
          <img src={logos.frame} alt="" />
          <img src={logos.igloo} alt="" />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            style={{
              background: headerTheme?.buttonBackground,
              color: headerTheme?.buttonColor,
            }}
            onClick={handleRequestQuote}
          >
            {formatMessage({ id: 'Request quote' })}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HeaderContent;
