import request from 'umi-request';

export const updateQuoteData = async (data: any) => {
  const res = await request('/v1/b2c-official/public/lead_capture?lang=en-US', {
    prefix: API_URL,
    method: 'post',
    data,
    manual: true,
  });
  return res;
};
