import { ComparisonTableData, ComparisonTheme } from 'typings';
import styles from './index.less';
import { useIntl } from 'react-intl';

const ComparisionTableRow: React.FC<{
  data: ComparisonTableData;
  theme: ComparisonTheme;
}> = ({ data, theme }) => {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.content}>
      <div
        className={styles.row}
        style={{
          background: theme.contentRowBackground,
          ...(data.borderBottom && {
            borderBottom: `1px solid ${theme.contentRowBackground}`,
          }),
        }}
      >
        <div
          className={styles.rowHeading}
          style={{
            ...(isMobile
              ? !data.rowHeadingText && !data.rowIcon
                ? { width: '80px', height: '40px' }
                : { minHeight: '70px' }
              : !data.rowHeadingText && !data.rowIcon && { height: '90px' }),
            background: theme.contentCellBackground,
          }}
        >
          <div className={styles.rowIcon}>
            <img src={data?.rowIcon} alt="" />
          </div>
          <div className={styles.rowText}>
            {data?.rowHeadingText
              ? formatMessage({ id: data?.rowHeadingText })
              : ''}
          </div>
        </div>
        <div className={styles.cellContainer}>
          {data?.rowData?.map((item: any, index: number) => {
            const items = item?.text?.split(' ');
            const item1 = items?.[0];
            const item2 = items?.[1];
            return (
              <div
                className={styles.cell}
                key={index}
                style={{
                  background: theme.contentCellBackground,
                  color: theme.titleColor,
                }}
              >
                {data.rowDataType === 'text' ? (
                  isMobile ? (
                    <div className={styles.mobileItem}>
                      <span
                        className={styles.item1}
                        style={{ color: theme.titleColor }}
                      >
                        {formatMessage({ id: item1 })}
                      </span>
                      <span
                        className={styles.item2}
                        style={{ color: theme.titleColor }}
                      >
                        {formatMessage({ id: item2 })}
                      </span>
                    </div>
                  ) : (
                    formatMessage({ id: item?.text })
                  )
                ) : (
                  <img src={item?.icon} alt="" />
                )}
                {item?.badge && (
                  <div
                    className={styles.badgeContainer}
                    style={{
                      background: theme.badgeBackground,
                      color: theme.badgeTextColor,
                    }}
                  >
                    <div className={styles.badge}>
                      {formatMessage({ id: item?.badgeText })}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ComparisionTableRow;
