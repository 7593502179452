import { ComparisonTableData, ComparisonTheme } from 'typings';
import styles from './index.less';
import { useIntl } from 'react-intl';

const data = {
  text: 'Third Party Liability',
};
const RowHeader: React.FC<{
  theme: ComparisonTheme;
  data: ComparisonTableData;
}> = ({ theme, data }) => {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.content}>
      <div
        className={styles.container}
        style={{
          background: theme.headingBackground,
          ...(data.headingBorder && {
            borderTopColor: theme.contentRowBackground,
            borderBottomColor: theme.contentRowBackground,
          }),
        }}
      >
        <div
          className={styles.rowBadge}
          style={{ background: theme.badgeBackground }}
        ></div>
        <div className={styles.rowHeading}>
          {formatMessage({ id: data.text })}
        </div>
      </div>
    </div>
  );
};

export default RowHeader;
