import { Input } from 'iglooform';
import styles from './index.less';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { AUTO_SPIN } from '@/constants';
import { Form } from 'antd';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { trackEvent } from '@/utils/ga';
import { fieldChangeEvent } from '@/utils/common';

const CustomTextInput: React.FC<{
  source: string;
  handleBlur: any;
  handleFocus: any;
  form: any;
  theme: any;
  setFieldErrors: Dispatch<SetStateAction<any>>;
  fieldErrors: any;
  site: string;
}> = ({
  handleBlur,
  handleFocus,
  source,
  form,
  theme,
  setFieldErrors,
  fieldErrors,
  site,
}) => {
  const { formatMessage } = useIntl();
  const customClass = useMemo(
    () =>
      !fieldErrors?.name?.length
        ? source === AUTO_SPIN
          ? styles.customizedDark
          : styles.customizedLight
        : '',
    [fieldErrors],
  );

  return (
    <Form.Item
      name="name"
      label={
        <span style={{ color: theme.labelColor, fontSize: '16px' }}>
          {formatMessage({
            id: 'Full name',
          })}
        </span>
      }
      rules={[
        {
          required: true,
          validator: (_: any, value) => {
            if (!value) {
              setFieldErrors((errors: any) => ({ ...errors, name: ['name'] }));
              return Promise.reject(
                formatMessage({
                  id: 'Please enter the proper name',
                }),
              );
            }
            setFieldErrors((errors: any) => ({
              ...errors,
              name: [],
            }));
            return Promise.resolve();
          },
        },
      ]}
      className={customClass}
    >
      <Input
        placeholder={formatMessage({
          id: 'Please enter your full name',
        })}
        onBlur={(e) => {
          handleBlur(e);
          if (e?.target?.value) {
            const data = fieldChangeEvent('name', e, site) || {};
            trackEvent('full_name_field', { ...data });
          }
        }}
        onFocus={handleFocus}
        className={classNames(styles.inputName, customClass)}
        onChange={(e) => form.setFieldValue('name', e.target.value)}
      />
    </Form.Item>
  );
};

export default CustomTextInput;
