import {
  AUTO_FUN,
  AUTO_FUN_CONTENT_STYLES,
  AUTO_SPIN,
  AUTO_SPIN_CONTENT_STYLES,
  BENEFIT_DATA,
  DEFAULT_YEAR_OPTIONS,
  EN_LOCALE,
  INDETERMINATE_BRAND,
  ONE_2_CAR_EN,
  ONE_2_CAR_TH,
  ONE_CAR,
  ONE_CAR_CONTENT_STYLES,
} from '@/constants';
import {
  AUTO_FUN_DESKTOP_ICONS,
  AUTO_FUN_MOBILE_ICONS,
  AUTO_SPIN_DESKTOP_ICONS,
  AUTO_SPIN_MOBILE_ICONS,
  BADGE_TEXT,
  BADGE_TEXT_MOBILE,
  COMPARISOMN_TABLE_DATA,
  ONE_CAR_DESKTOP_ICONS,
  ONE_CAR_MOBILE_ICONS,
  SECTION_DATA,
} from '@/constants/comparisiondata';
import { MODEL_OPTIONS } from '@/constants/brand-model';
import {
  AUTOFUN_STYLES,
  AUTO_FUN_DESKTOP_LOGOS,
  AUTO_FUN_MOBILE_LOGOS,
  AUTO_SPIN_DESKTOP_LOGOS,
  AUTO_SPIN_MOBILE_LOGOS,
  AUTO_SPIN_STYLES,
  BENEFIT_ICONS,
  CLOSE_ICON_DESKTOP,
  CLOSE_ICON_MOBILE,
  INSURER_DESKTOP,
  INSURER_MOBILE,
  ONE_2_CAR_DESKTOP_LOGOS,
  ONE_2_CAR_MOBILE_LOGOS,
  ONE_CAR_STYLES,
} from '@/constants/logo';
import { GAPayload, ModelOptions, MyObject, ObjectKeys } from 'typings';
import AUTO_FUN_NO_CONTENT from '@/assets/desktop/autoFun/notFound.svg';
import AUTO_SPIN_NO_CONTENT from '@/assets/desktop/autoSpin/autospinn.svg';
import ONE_CAR_NO_CONTENT from '@/assets/desktop/one2car/one2car.svg';
import Cookies from 'js-cookie';
import { FORM_FIELD_NAMES } from '@/constants';

const { NAME, EMAIL, NUMBER, INSURANCE_TYPE, MODEL, YEAR } = FORM_FIELD_NAMES;

const getInitialDataForSource = (source?: string) => {
  switch (source) {
    case AUTO_FUN:
      return {
        logos: isMobile ? AUTO_FUN_MOBILE_LOGOS : AUTO_FUN_DESKTOP_LOGOS,
        styles: AUTOFUN_STYLES,
      };
    case ONE_CAR:
      return {
        logos: isMobile ? ONE_2_CAR_MOBILE_LOGOS : ONE_2_CAR_DESKTOP_LOGOS,
        styles: ONE_CAR_STYLES,
      };
    case AUTO_SPIN:
      return {
        logos: isMobile ? AUTO_SPIN_MOBILE_LOGOS : AUTO_SPIN_DESKTOP_LOGOS,
        styles: AUTO_SPIN_STYLES,
      };
    default:
      return {
        logos: isMobile ? AUTO_FUN_MOBILE_LOGOS : AUTO_FUN_DESKTOP_LOGOS,
        styles: AUTOFUN_STYLES,
      };
  }
};

const getMainContentStyls = (source?: string) => {
  switch (source) {
    case AUTO_FUN:
      return AUTO_FUN_CONTENT_STYLES;
    case ONE_CAR:
      return ONE_CAR_CONTENT_STYLES;
    case AUTO_SPIN:
      return AUTO_SPIN_CONTENT_STYLES;
    default:
      return AUTO_FUN_CONTENT_STYLES;
  }
};

const getBenefitIcons = (source: string) => {
  const { autofun, autospinn, one2car } = BENEFIT_ICONS;
  switch (source) {
    case AUTO_FUN:
      return {
        icon1: isMobile ? autofun.icon1.mobile : autofun.icon1.desktop,
        icon2: isMobile ? autofun.icon2.mobile : autofun.icon2.desktop,
        icon3: isMobile ? autofun.icon3.mobile : autofun.icon3.desktop,
      };
    case AUTO_SPIN:
      return {
        icon1: isMobile ? autospinn.icon1.mobile : autospinn.icon1.desktop,
        icon2: isMobile ? autospinn.icon2.mobile : autospinn.icon2.desktop,
        icon3: isMobile ? autospinn.icon3.mobile : autospinn.icon3.desktop,
      };
    case ONE_CAR:
      return {
        icon1: isMobile ? one2car.icon1.mobile : one2car.icon1.desktop,
        icon2: isMobile ? one2car.icon2.mobile : one2car.icon2.desktop,
        icon3: isMobile ? one2car.icon3.mobile : one2car.icon3.desktop,
      };
  }
};

const getBenefitData = (source: string) => {
  const icons = getBenefitIcons(source);
  const data = [
    {
      ...BENEFIT_DATA[0],
      icon: icons?.icon1,
    },
    {
      ...BENEFIT_DATA[1],
      icon: icons?.icon2,
    },
    {
      ...BENEFIT_DATA[2],
      icon: icons?.icon3,
    },
  ];
  return data;
};

const getInsurerLogos = () => (isMobile ? INSURER_MOBILE : INSURER_DESKTOP);

const getComparisonRowIcons = (source: string) => {
  switch (source) {
    case AUTO_FUN:
      return isMobile ? AUTO_FUN_MOBILE_ICONS : AUTO_FUN_DESKTOP_ICONS;
    case ONE_CAR:
      return isMobile ? ONE_CAR_MOBILE_ICONS : ONE_CAR_DESKTOP_ICONS;
    case AUTO_SPIN:
      return isMobile ? AUTO_SPIN_MOBILE_ICONS : AUTO_SPIN_DESKTOP_ICONS;
  }
};

const getComparisonTableData = (source: string) => {
  const icons = getComparisonRowIcons(source);
  const rowData = COMPARISOMN_TABLE_DATA;
  const modifiedData = rowData.map((item: any, index) => {
    const isContentRow =
      item.rowType === 'content' &&
      item.rowHeadingText &&
      item.rowDataType === 'icon';
    const headerBadgeText = isMobile ? BADGE_TEXT_MOBILE : BADGE_TEXT;

    let modifiedItem = { ...item };
    if (isContentRow) {
      modifiedItem.rowIcon = icons?.[0];
      const modifiedData = modifiedItem.rowData.map((rowItem: any) => {
        const newData = {
          ...rowItem,
          icon: rowItem.iconCondition ? icons?.[7] : icons?.[8],
        };
        return newData;
      });
      modifiedItem.rowData = [...modifiedData];
    }
    if (Number(index) === 0) {
      modifiedItem.rowData = modifiedItem.rowData.map(
        (rowItem: any, rowIndex: any) => {
          const appendBadge = Number(rowIndex) === 1;
          const newData = {
            ...rowItem,
            ...(appendBadge && { badgeText: headerBadgeText }),
          };
          return newData;
        },
      );
    }
    if (source !== AUTO_SPIN) {
      modifiedItem.headingBorder = true;
    }
    return modifiedItem;
  });
  return {
    ...SECTION_DATA,
    tableData: modifiedData,
  };
};

const getYearOptions = (data?: any) => {
  const date = new Date();
  const year_end = date.getFullYear();
  const year_begin = Number(year_end) - 24;
  const yearOptions = Array.from(
    { length: year_end - year_begin + 1 },
    (_, index) => year_begin + index,
  ).reduce((acc: any, year: any) => {
    const value = `${year} / ${year + 543}`;
    acc.push({
      label: value,
      value: value,
      year: year,
    });
    return acc;
  }, []);
  return yearOptions?.sort((a: any, b: any) => b.year - a.year);
};

const getModelOptions = (searchTerm: any) => {
  const unique: MyObject = {};
  let searchTermObj: any = {};
  let data = MODEL_OPTIONS?.map((item: any) => {
    const options = {
      label: item.name.trim(),
      value: item.name.trim(),
      year_begin: item.year_begin.trim(),
      year_end: item.year_end.trim(),
      yearOptions: getYearOptions(),
      brand: item.brand,
      sub_model: item.sub_model,
      model: item?.model,
    };
    return options;
  });

  if (!!searchTerm) {
    searchTermObj = {
      label: searchTerm?.toUpperCase(),
      value: searchTerm,
      ...DEFAULT_YEAR_OPTIONS,
      yearOptions: getYearOptions(),
      brand: INDETERMINATE_BRAND,
      model: searchTerm,
    };
    data = [{ ...searchTermObj }, ...data];
  }

  data.forEach((item) => {
    if (unique?.[item?.label]) {
      const storedItem = unique?.[item?.label];
      if (
        storedItem.year_begin !== item?.year_begin ||
        storedItem.year_end !== item.year_end
      ) {
        const newOptions = getYearOptions(item);
        unique[item?.label].yearOptions = [
          ...storedItem.yearOptions,
          ...newOptions,
        ].sort((a: any, b: any) => b.value - a.value);
      }
    } else {
      unique[item.label] = {
        ...item,
        yearOptions: getYearOptions(item)?.sort(
          (a: any, b: any) => b.year - a.year,
        ),
      };
    }
  });

  return Object.values(unique);
};

const getYearOptionsData = (model?: string, modelOptions?: ModelOptions[]) => {
  const models = modelOptions;
  const currentSelection = models?.filter((item) => item.label === model)?.[0];
  const yearOptions = getYearOptions(currentSelection);
  return yearOptions;
};

const getNotFoundLogo = (source: string) => {
  switch (source) {
    case AUTO_FUN:
      return AUTO_FUN_NO_CONTENT;
    case AUTO_SPIN:
      return AUTO_SPIN_NO_CONTENT;
    case ONE_CAR:
      return ONE_CAR_NO_CONTENT;
    default:
      return AUTO_FUN_NO_CONTENT;
  }
};

const getApiPayload = (values: any, source: any, modelDetails: any) => {
  const sourceType = `icar-${source}-TH`;
  const mobile_number = '+66' + values?.number.split('-').join('');
  const yearValues = values?.year?.split('/').map((item: any) => item?.trim());
  const yom =
    yearValues?.[0] && yearValues?.[1]
      ? values?.year
      : yearValues?.[0] || yearValues?.[1];

  const obj = {
    data: {
      mobile_number: mobile_number,
      user_type: 'guestUser',
      ...(source === ONE_CAR && values?.email && { email: values?.email }),
      product: 'iCar',
      entry_point: 'iCarLandingPage',
      source: sourceType,
      insurance_type: values?.insuranceType,
      user_name: values?.name,
      fast_quote: {
        i_car: {
          brand: modelDetails?.brand,
          manufacture_year: yom || values?.year,
          series: modelDetails?.model,
        },
      },
    },
  };
  return obj;
};

const getDocURL = (locale: any) =>
  locale === EN_LOCALE
    ? 'https://static.iglooinsure.com/vertical/b2c/iCar/Terms/PrivacyNotice_EN.pdf'
    : 'https://static.iglooinsure.com/vertical/b2c/iCar/Terms/PrivacyNotice_TH.pdf';

const getCloseIcon = (source: string) => {
  const icons: ObjectKeys = isMobile ? CLOSE_ICON_MOBILE : CLOSE_ICON_DESKTOP;
  const icon = icons?.[String(source)];
  return icon;
};

const getCookie = (key: string) => {
  const value = Cookies.get(key);
  return value ? JSON.parse(value) : '';
};

const getUniqueUserIdentifier = (length: any = 16) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const getGASource = (source: string, locale: string) => {
  const one2carSource = locale === EN_LOCALE ? ONE_2_CAR_EN : ONE_2_CAR_TH;
  return source === ONE_CAR ? one2carSource : source?.toLocaleLowerCase();
};

const getGALeadsData = (payload: GAPayload) => {
  const leadsObj = {
    fullname: payload?.user_name,
    email: payload?.email || 'NA',
    phone_number: payload?.mobile_number,
    insurance_type: payload?.insurance_type,
    vehicle_brand:
      payload?.fast_quote?.i_car?.brand +
      ' ' +
      payload?.fast_quote?.i_car?.series,
    vehicle_yom: payload?.fast_quote?.i_car?.manufacture_year,
  };
  return leadsObj;
};

const getNumberFormat = (phoneNumber: number) =>
  phoneNumber ? '+66' + phoneNumber : '';

const fieldChangeEvent = (fieldName: string, event: any, site: string) => {
  let initialValue = { Site: site };
  const value = event?.target?.value;
  switch (fieldName) {
    case NAME:
      return { ...initialValue, fullname: value };
    case NUMBER:
      const number = value?.split('-')?.join('');
      return {
        ...initialValue,
        phone_number: number ? getNumberFormat(number) : '',
      };
    case EMAIL:
      return {
        ...initialValue,
        email: value || '',
      };
    case INSURANCE_TYPE:
      return {
        ...initialValue,
        insurance_type: value,
      };
    case MODEL:
      return {
        ...initialValue,
        vehicle_brand: event,
      };
    case YEAR:
      return {
        ...initialValue,
        vehicle_yom: event,
      };
  }
};

export {
  getInitialDataForSource,
  getMainContentStyls,
  getBenefitIcons,
  getBenefitData,
  getInsurerLogos,
  getComparisonTableData,
  getModelOptions,
  getYearOptions,
  getYearOptionsData,
  getNotFoundLogo,
  getApiPayload,
  getDocURL,
  getCloseIcon,
  getUniqueUserIdentifier,
  getCookie,
  getGASource,
  getGALeadsData,
  getNumberFormat,
  fieldChangeEvent,
};
