import FAQ from '@/components/faq';
import QuoteRedirect from '@/components/quote-redirect';
import {
  AUTO_FUN,
  AUTO_SPIN,
  EASY_SCORE_ITEM_DATA,
  ONE_CAR,
} from '@/constants';
import {
  getBenefitData,
  getCloseIcon,
  getComparisonTableData,
  getGASource,
  getInsurerLogos,
  getMainContentStyls,
} from '@/utils/common';
import { useEffect, useMemo } from 'react';
import styles from './index.less';
import IcarBenefit from '@/components/benefit';
import EasyScore from '@/components/easy-score';
import InsurerLogoSection from '@/components/insurer-logo';
import { useLocation } from 'umi';
import HeroBanner from '@/components/banner';
import ComparisionTable from '@/components/comparison-table';
import QuoteForm from '@/components/quote-form';
import { trackEvent } from '@/utils/ga';
import { useIntl } from 'react-intl';

export default function HomePage() {
  const location: any = useLocation();
  const params = location?.query || {};
  const { locale } = useIntl();

  const source = useMemo(() => {
    let partner = params?.utm_source || '';
    const sourceParam =
      partner && [AUTO_FUN, AUTO_SPIN, ONE_CAR]?.includes(partner)
        ? partner
        : AUTO_FUN;
    return sourceParam;
  }, [params]);

  useEffect(() => {
    trackEvent('icar_page_view', {
      Site: getGASource(source, locale),
    });
  }, []);

  const {
    quoteRedirectStyles,
    faqStyles,
    color,
    benefitStyles,
    benefitData,
    easyScoreStyles,
    easyScoreData,
    insurerLogoStyles,
    logos,
    comparisonTableStyles,
    comparisionData,
    quoteFormStyles,
    closeIcon,
  } = useMemo(() => {
    const stylesData = getMainContentStyls(source);
    const benefitData = getBenefitData(source);
    const easyScoreData = [...EASY_SCORE_ITEM_DATA];
    const logos = getInsurerLogos();
    const comparisionData = getComparisonTableData(source);
    const closeIcon = getCloseIcon(source);
    return {
      ...stylesData,
      benefitData,
      easyScoreData,
      logos,
      comparisionData,
      closeIcon,
    };
  }, [source]);
  return (
    <div className={styles.main} style={{ color: color }}>
      <HeroBanner source={source} />
      <QuoteForm
        theme={quoteFormStyles}
        source={source}
        closeIcon={closeIcon}
      />
      <ComparisionTable
        theme={comparisonTableStyles}
        data={comparisionData}
        source={source}
      />
      <InsurerLogoSection theme={insurerLogoStyles} data={logos} />
      <EasyScore theme={easyScoreStyles} data={easyScoreData} />
      <IcarBenefit theme={benefitStyles} data={benefitData} source={source} />
      <FAQ theme={faqStyles} source={source} />
      <QuoteRedirect theme={quoteRedirectStyles} source={source} />
    </div>
  );
}
