import {
  fieldChangeEvent,
  getModelOptions,
  getNotFoundLogo,
} from '@/utils/common';
import crossIcon from '@/assets/cancel_24px.svg';
import { Select } from 'iglooform';
import { Dispatch, SetStateAction, useMemo } from 'react';
import styles from './index.less';
import { useIntl } from 'react-intl';
import { AUTO_SPIN } from '@/constants';
import classNames from 'classnames';
import { trackEvent } from '@/utils/ga';

const CustomSelect: React.FC<{
  source: string;
  form: any;
  setModelDetails: Dispatch<SetStateAction<any>>;
  setYearOptions: Dispatch<SetStateAction<any>>;
  setSearchVal: Dispatch<SetStateAction<any>>;
  setFieldErrors: Dispatch<SetStateAction<any>>;
  fieldErrors: any;
  searchVal: any;
  highlightMatchedText: any;
  handleFocus: any;
  handleBlur: any;
  site: string;
}> = ({
  source,
  form,
  setModelDetails,
  setYearOptions,
  setSearchVal,
  fieldErrors,
  searchVal,
  highlightMatchedText,
  handleBlur,
  handleFocus,
  site,
}) => {
  const notFoundIcon = useMemo(() => getNotFoundLogo(source), [source]);
  const { formatMessage } = useIntl();
  const customClass = useMemo(
    () =>
      !fieldErrors?.model?.length
        ? source === AUTO_SPIN
          ? styles.customizedDark
          : styles.customizedLight
        : '',
    [fieldErrors, source],
  );
  return (
    <Select
      placeholder="Eg. Toyota Yaris ATIV"
      options={getModelOptions(searchVal)}
      style={{ width: '100%' }}
      suffixIcon={null}
      allowClear={{
        clearIcon: (
          <img src={crossIcon} style={{ fontSize: 14, color: '#5858FF' }} />
        ),
      }}
      getPopupContainer={(trigger: any) => trigger?.parentNode}
      showSearch
      onChange={(v, option) => {
        const values = fieldChangeEvent('model', v, site);
        trackEvent('vehicle_brand_field', {
          ...values,
        });
        const optionData: any = option;
        setModelDetails(option);
        form.setFieldsValue({ model: v });
      }}
      onSearch={(value) => setSearchVal(value)}
      optionLabelProp="label"
      optionFilterProp="label"
      filterOption={(inputValue, option: any) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      }
      optionRender={(option) => {
        return highlightMatchedText(option.label, searchVal);
      }}
      notFoundContent={
        <div className={styles.notFound}>
          <img src={notFoundIcon} alt="No Results" style={{ fill: 'blue' }} />
          <div className={styles.text}>
            {formatMessage({ id: 'No search result' })}
          </div>
        </div>
      }
      className={classNames(styles.content, customClass)}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
};

export default CustomSelect;
