import autoFunRowIcon1 from '@/assets/desktop/autoFun/comparison/rowIcon1.svg';
import autoFunRowIcon2 from '@/assets/desktop/autoFun/comparison/rowIcon2.svg';
import autoFunRowIcon3 from '@/assets/desktop/autoFun/comparison/rowIcon3.svg';
import autoFunRowIcon4 from '@/assets/desktop/autoFun/comparison/rowIcon4.svg';
import autoFunRowIcon5 from '@/assets/desktop/autoFun/comparison/rowIcon5.svg';
import autoFunRowIcon6 from '@/assets/desktop/autoFun/comparison/rowIcon6.svg';
import autoFunRowIcon7 from '@/assets/desktop/autoFun/comparison/rowIcon7.svg';
import autoFuncheck from '@/assets/desktop/autoFun/comparison/check.svg';
import autoFuncross from '@/assets/desktop/autoFun/comparison/cross.svg';

import mobileAutoFun1 from '@/assets/mobile/autoFun/comparison/rowIcon1.svg';
import mobileAutoFun2 from '@/assets/mobile/autoFun/comparison/rowIcon2.svg';
import mobileAutoFun3 from '@/assets/mobile/autoFun/comparison/rowIcon3.svg';
import mobileAutoFun4 from '@/assets/mobile/autoFun/comparison/rowIcon4.svg';
import mobileAutoFun5 from '@/assets/mobile/autoFun/comparison/rowIcon5.svg';
import mobileAutoFun6 from '@/assets/mobile/autoFun/comparison/rowIcon6.svg';
import mobileAutoFun7 from '@/assets/mobile/autoFun/comparison/rowIcon7.svg';
import mobileAutoFuncheck from '@/assets/mobile/autoFun/comparison/check.svg';
import mobileAutoFuncross from '@/assets/mobile/autoFun/comparison/cross.svg';

import autoSpinRowIcon1 from '@/assets/desktop/autoSpin/comparison/rowIcon1.svg';
import autoSpinRowIcon2 from '@/assets/desktop/autoSpin/comparison/rowIcon2.svg';
import autoSpinRowIcon3 from '@/assets/desktop/autoSpin/comparison/rowIcon3.svg';
import autoSpinRowIcon4 from '@/assets/desktop/autoSpin/comparison/rowIcon4.svg';
import autoSpinRowIcon5 from '@/assets/desktop/autoSpin/comparison/rowIcon5.svg';
import autoSpinRowIcon6 from '@/assets/desktop/autoSpin/comparison/rowIcon6.svg';
import autoSpinRowIcon7 from '@/assets/desktop/autoSpin/comparison/rowIcon7.svg';
import autoSpincheck from '@/assets/desktop/autoSpin/comparison/Vector.svg';
import autoSpincross from '@/assets/desktop/autoSpin/comparison/Frame.svg';

import mobileautoSpin1 from '@/assets/mobile/autoSpin/comparison/rowIcon1.svg';
import mobileautoSpin2 from '@/assets/mobile/autoSpin/comparison/rowIcon2.svg';
import mobileautoSpin3 from '@/assets/mobile/autoSpin/comparison/rowIcon3.svg';
import mobileautoSpin4 from '@/assets/mobile/autoSpin/comparison/rowIcon4.svg';
import mobileautoSpin5 from '@/assets/mobile/autoSpin/comparison/rowIcon5.svg';
import mobileautoSpin6 from '@/assets/mobile/autoSpin/comparison/rowIcon6.svg';
import mobileautoSpin7 from '@/assets/mobile/autoSpin/comparison/rowIcon7.svg';
import mobileautoSpincheck from '@/assets/mobile/autoSpin/comparison/check.svg';
import mobileautoSpincross from '@/assets/mobile/autoSpin/comparison/cross.svg';

import one2carRowIcon1 from '@/assets/desktop/one2car/comparison/rowIcon1.svg';
import one2carRowIcon2 from '@/assets/desktop/one2car/comparison/rowIcon2.svg';
import one2carRowIcon3 from '@/assets/desktop/one2car/comparison/rowIcon3.svg';
import one2carRowIcon4 from '@/assets/desktop/one2car/comparison/rowIcon4.svg';
import one2carRowIcon5 from '@/assets/desktop/one2car/comparison/rowIcon5.svg';
import one2carRowIcon6 from '@/assets/desktop/one2car/comparison/rowIcon6.svg';
import one2carRowIcon7 from '@/assets/desktop/one2car/comparison/rowIcon7.svg';
import one2carcheck from '@/assets/desktop/one2car/comparison/check.svg';
import one2carcross from '@/assets/desktop/one2car/comparison/cross.svg';

import mobileone2car1 from '@/assets/mobile/one2Car/comparison/rowIcon1.svg';
import mobileone2car2 from '@/assets/mobile/one2Car/comparison/rowIcon2.svg';
import mobileone2car3 from '@/assets/mobile/one2Car/comparison/rowIcon3.svg';
import mobileone2car4 from '@/assets/mobile/one2Car/comparison/rowIcon4.svg';
import mobileone2car5 from '@/assets/mobile/one2Car/comparison/rowIcon5.svg';
import mobileone2car6 from '@/assets/mobile/one2Car/comparison/rowIcon6.svg';
import mobileone2car7 from '@/assets/mobile/one2Car/comparison/rowIcon7.svg';
import mobileone2carcheck from '@/assets/mobile/one2Car/comparison/check.svg';
import mobileone2carcross from '@/assets/mobile/one2Car/comparison/cross.svg';

export const BADGE_TEXT = 'Popular Choice';
export const BADGE_TEXT_MOBILE = 'Popular';

export const AUTO_FUN_DESKTOP_ICONS = [
  autoFunRowIcon1,
  autoFunRowIcon2,
  autoFunRowIcon3,
  autoFunRowIcon4,
  autoFunRowIcon5,
  autoFunRowIcon6,
  autoFunRowIcon7,
  autoFuncheck,
  autoFuncross,
];

export const AUTO_FUN_MOBILE_ICONS = [
  mobileAutoFun1,
  mobileAutoFun2,
  mobileAutoFun3,
  mobileAutoFun4,
  mobileAutoFun5,
  mobileAutoFun6,
  mobileAutoFun7,
  mobileAutoFuncheck,
  mobileAutoFuncross,
];
export const AUTO_SPIN_DESKTOP_ICONS = [
  autoSpinRowIcon1,
  autoSpinRowIcon2,
  autoSpinRowIcon3,
  autoSpinRowIcon4,
  autoSpinRowIcon5,
  autoSpinRowIcon6,
  autoSpinRowIcon7,
  autoSpincheck,
  autoSpincross,
];

export const AUTO_SPIN_MOBILE_ICONS = [
  mobileautoSpin1,
  mobileautoSpin2,
  mobileautoSpin3,
  mobileautoSpin4,
  mobileautoSpin5,
  mobileautoSpin6,
  mobileautoSpin7,
  mobileautoSpincheck,
  mobileautoSpincross,
];

export const ONE_CAR_DESKTOP_ICONS = [
  one2carRowIcon1,
  one2carRowIcon2,
  one2carRowIcon3,
  one2carRowIcon4,
  one2carRowIcon5,
  one2carRowIcon6,
  one2carRowIcon7,
  one2carcheck,
  one2carcross,
];

export const ONE_CAR_MOBILE_ICONS = [
  mobileone2car1,
  mobileone2car2,
  mobileone2car3,
  mobileone2car4,
  mobileone2car5,
  mobileone2car6,
  mobileone2car7,
  mobileone2carcheck,
  mobileone2carcross,
];

export const SECTION_DATA = {
  title: 'Comparison of the different types of car insurance coverage',
  desc: 'Below is a table of what damages each type of car insurance covers in the event of a car accident on the road, including repairs and medical bills.',
  noteText: '*When the third party is another vehicle',
  bottomText:
    'Still not sure which car insurance to get? Contact us on Line and our customer support will help you.',
};

export const COMPARISOMN_TABLE_DATA = [
  {
    rowIcon: '',
    rowHeadingText: '',
    rowDataType: 'text',
    rowType: 'content',
    borderBottom: false,
    rowData: [
      {
        text: 'Type 1',
        badge: false,
        icon: '',
      },
      {
        text: 'Type 2+',
        badge: true,
        icon: '',
      },
      {
        text: 'Type 2',
        badge: false,
        icon: '',
      },
      {
        text: 'Type 3+',
        badge: false,
        icon: '',
      },
      {
        text: 'Type 3',
        badge: false,
        icon: '',
      },
    ],
  },
  {
    rowIcon: '',
    rowHeadingText: '',
    rowDataType: '',
    rowType: 'heading',
    borderBottom: false,
    text: 'Third Party Liability',
  },
  {
    rowIcon: '',
    rowHeadingText: 'Bodily Injury / Death',
    rowDataType: 'icon',
    rowType: 'content',
    borderBottom: true,
    rowData: [
      {
        icon: '',
        iconCondition: true,
      },
      {
        icon: '',
        iconCondition: true,
      },
      {
        icon: '',
        iconCondition: true,
      },
      {
        icon: '',
        iconCondition: true,
      },
      {
        icon: '',
        iconCondition: true,
      },
    ],
  },
  {
    rowIcon: '',
    rowHeadingText: 'Property Damage',
    rowDataType: 'icon',
    borderBottom: false,
    rowType: 'content',
    rowData: [
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
    ],
  },
  {
    rowIcon: '',
    rowHeadingText: '',
    rowDataType: '',
    rowType: 'heading',
    borderBottom: false,
    text: 'Insured Vehicle',
  },
  {
    rowIcon: '',
    rowHeadingText: 'Own Damage',
    rowDataType: 'icon',
    borderBottom: true,
    rowType: 'content',
    rowData: [
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: false,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: false,
        icon: '',
      },
    ],
  },
  {
    rowIcon: '',
    rowHeadingText: 'Theft and Fire',
    rowDataType: 'icon',
    borderBottom: false,
    rowType: 'content',
    rowData: [
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: false,
        icon: '',
      },
      {
        iconCondition: false,
        icon: '',
      },
    ],
  },
  {
    rowIcon: '',
    rowHeadingText: '',
    rowDataType: '',
    borderBottom: false,
    rowType: 'heading',
    text: 'Additional Coverage',
  },
  {
    rowIcon: '',
    rowHeadingText: 'Personal Accident',
    rowDataType: 'icon',
    borderBottom: true,
    rowType: 'content',
    rowData: [
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
    ],
  },
  {
    rowIcon: '',
    rowHeadingText: 'Medical',
    rowDataType: 'icon',
    borderBottom: true,
    rowType: 'content',
    rowData: [
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
    ],
  },
  {
    rowIcon: '',
    rowHeadingText: 'Bail Bond',
    rowDataType: 'icon',
    borderBottom: false,
    rowType: 'content',
    rowData: [
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
      {
        iconCondition: true,
        icon: '',
      },
    ],
  },
];
