import { useIntl } from 'react-intl';
import styles from './index.less';
import { Modal } from 'antd';
import Success from '@/assets/desktop/success.svg';
import { Dispatch, SetStateAction } from 'react';

const SuccessModal: React.FC<{
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  closeIcon: string;
}> = ({ openModal, setOpenModal, closeIcon }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.thankYouMessageSection}>
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        className={styles.modelStyles}
        centered
        closeIcon={<img src={closeIcon} alt="close icon" />}
      >
        <div className={styles.successIcon}>
          <img src={Success} alt="" />
        </div>
        <div className={styles.mainDiv}>
          <span className={styles.header}>
            {formatMessage({ id: 'Thank you for submitting the form' })}
          </span>
          <div className={styles.description}>
            {formatMessage({
              id: 'Our team will contact you back within 1 working day',
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SuccessModal;
