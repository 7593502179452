import { Typography } from 'iglooform';
import styles from './index.less';
import { useIntl } from 'react-intl';
import React from 'react';

const FooterContent: React.FC<{ logo: any }> = ({ logo }) => {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Typography level="h5">Powered By</Typography>
          <img src={logo} alt="Footer Logo" />
        </div>
        <div className={styles.details}>
          {formatMessage({
            id: 'Siam TMI Insurance Broker Co.,Ltd Broker License number ว0015/2538',
          })}
          <br />
          บจก.สยามทีเอ็มไอ นายหน้าประกันภัย
          <br />
          ใบอนุญาตเลขที่ ว0015/2538
        </div>
      </div>
    </div>
  );
};

export default FooterContent;
