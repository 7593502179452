import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'umi';
import { Layout } from 'antd';
import HeaderContent from '../components/header/index';
import styles from './index.less';
import {
  AUTO_FUN,
  AUTO_SPIN,
  EN_LOCALE,
  ONE_CAR,
  TH_LOCALE,
  USER_ID,
} from '@/constants';
import {
  getCookie,
  getInitialDataForSource,
  getUniqueUserIdentifier,
} from '@/utils/common';
import FooterContent from '@/components/footer';
import en from '@/locales/en-US';
import th from '@/locales/th-TH';
import { IntlProvider } from 'react-intl';
import logo from '@/assets/32@3x.svg';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';

const locales: any = {
  'en-US': en,
  'th-TH': th,
};

const { Header, Footer, Content } = Layout;

const GlobalLayout: React.FC<{ children: any }> = ({ children }) => {
  const location: any = useLocation();
  const params = location?.query || {};
  const [source, lang] = useMemo(() => {
    let lang = params?.utm_campaign?.split('=')?.[1] || '';
    let partner = params?.utm_source || '';
    const sourceParam =
      partner && [AUTO_FUN, AUTO_SPIN, ONE_CAR]?.includes(partner)
        ? partner
        : '';
    localStorage.setItem('currentLocale', lang);
    lang = lang === 'en' ? EN_LOCALE : TH_LOCALE;
    localStorage.setItem('currentLang', lang);
    localStorage.setItem('source', sourceParam);
    return [sourceParam, lang];
  }, [params]);

  const { logos, styles: themeStyle } = useMemo(() => {
    const content = getInitialDataForSource(source);
    return content;
  }, [source]);

  const helmet = (
    <Helmet>
      <title>Motor Insurance for All</title>
      <link rel="icon" type="image/png" href={logo} sizes="16x16" />
    </Helmet>
  );

  useEffect(() => {
    const userID = getCookie(USER_ID);
    if (!userID) {
      const uuid = getUniqueUserIdentifier();
      Cookies.set(USER_ID, JSON.stringify(uuid));
    }
  }, []);

  return !!source && !!lang ? (
    <IntlProvider locale={lang} messages={locales[lang]}>
      {helmet}
      <Layout className={styles.layout}>
        <Header
          className={styles.header}
          style={{ background: themeStyle?.headerStyles?.backgroundColor }}
        >
          <HeaderContent
            logos={logos}
            headerTheme={themeStyle?.headerStyles}
            source={source}
          />
        </Header>
        <Content className={styles?.content}>{children}</Content>
        <Footer
          className={styles.footer}
          style={{
            backgroundColor: themeStyle?.footerStyles?.backgroundColor,
            color: themeStyle?.footerStyles?.color,
          }}
        >
          <FooterContent logo={logos?.footerLogo} />
        </Footer>
      </Layout>
    </IntlProvider>
  ) : (
    <div></div>
  );
};

export default GlobalLayout;
