export const AUTO_FUN = 'autofun';
export const AUTO_SPIN = 'autospinn';
export const ONE_CAR = 'one2car';

export const AUTO_FUN_CONTENT_STYLES = {
  color: '#1E1E1E',
  quoteFormStyles: {
    backgroundColor: '#F9F9F9',
    contentBg: '#FFF',
    titleColor: '#1E1E1E',
    descColor: 'rgba(30, 30, 30, 0.6)',
    labelColor: '#1E1E1E',
    badgeBg: '#FFC100',
    badgeColor: '#1E1E1E',
    radioActive: '#FFF',
    tandcColor: 'rgba(30, 30, 30, 0.40)',
    buttonBackground: '#1E1E1E',
    buttonColor: '#FFC400',
  },
  comparisonTableStyles: {
    backgroundColor: '#F9F9F9',
    titleColor: '#1E1E1E',
    descColor: 'rgba(30, 30, 30, 0.60)',
    contentRowBackground: '#D9D9D9',
    contentCellBackground: '#FFF',
    headingBackground: '#F9F9F9',
    badgeBackground: '#FFC100',
    badgeTextColor: '#1E1E1E',
    noteTextColor: 'rgba(30, 30, 30, 0.40)',
    bottomTextColor: 'rgba(30, 30, 30, 0.60)',
  },
  insurerLogoStyles: {
    bacgroundColor: '#FFF',
    titleColor: '#1E1E1E',
  },
  easyScoreStyles: {
    bacgroundColor: '#FFC100',
    titleColor: '#1E1E1E',
    itemBackgroundColor: '#FFF',
    iconBgColor: '#FFC100',
    iconTextColor: '#1E1E1E',
    itemTextColor: '#1E1E1E',
    itemDescColor: 'rgba(30, 30, 30, 0.60)',
  },
  benefitStyles: {
    bacgroundColor: '#F9F9F9',
    titleColor: '#1E1E1E',
    iCarTextColor: '#FFC400',
    itemTitleColor: '#1E1E1E',
    itemDescColor: 'rgba(30, 30, 30, 0.60)',
    itemBackgroundColor: '#FFF',
  },
  faqStyles: {
    bacgroundColor: '#FFF',
    iconColor: '#1E1E1E',
    questionColor: '#1E1E1E',
    answerColor: 'rgba(30, 30, 30, 0.60)',
  },
  quoteRedirectStyles: {
    backgroundColor: ' #FFC100',
    buttonBackground: '#1E1E1E',
    buttonColor: '#FFC400',
  },
};
export const ONE_CAR_CONTENT_STYLES = {
  color: '#212E3A',
  quoteFormStyles: {
    backgroundColor: '#F9F9F9',
    contentBg: '#FFF',
    titleColor: '#212E3A',
    descColor: 'rgba(33, 46, 58, 0.60)',
    labelColor: '#212E3A',
    badgeBg: '#E00',
    badgeColor: '#FFF',
    radioActive: '#FFF',
    tandcColor: 'rgba(30, 30, 30, 0.40)',
    buttonBackground: '#FFD800',
    buttonColor: '#212E3A',
  },
  comparisonTableStyles: {
    backgroundColor: '#F9F9F9',
    titleColor: '#212E3A',
    descColor: 'rgba(33, 46, 58, 0.60)',
    contentRowBackground: '#D9D9D9',
    contentCellBackground: '#FFF',
    headingBackground: '#F9F9F9',
    badgeBackground: '#E00',
    badgeTextColor: '#FFF',
    noteTextColor: 'rgba(33, 46, 58, 0.40)',
    bottomTextColor: 'rgba(33, 46, 58, 0.60)',
  },
  insurerLogoStyles: {
    bacgroundColor: '#FFF',
    titleColor: '#212E3A',
  },
  easyScoreStyles: {
    bacgroundColor: '#212E3A',
    titleColor: '#FFF',
    itemBackgroundColor: '#FFF',
    iconBgColor: '#E00',
    iconTextColor: '#212E3A',
    itemTextColor: '#212E3A',
    itemDescColor: 'rgba(33, 46, 58, 0.60)',
  },
  faqStyles: {
    bacgroundColor: '#FFF',
    iconColor: '#242E39',
    questionColor: '#212E3A',
    answerColor: 'rgba(33, 46, 58, 0.60)',
  },
  quoteRedirectStyles: {
    backgroundColor: '#F9F9F9',
    buttonBackground: '#FFD800',
    buttonColor: '#212E3A',
  },
  benefitStyles: {
    bacgroundColor: '#F9F9F9',
    titleColor: '#212E3A',
    iCarTextColor: '#DA0000',
    itemTitleColor: '#212E3A',
    itemDescColor: 'rgba(33, 46, 58, 0.60)',
    itemBackgroundColor: '#FFF',
  },
};
export const AUTO_SPIN_CONTENT_STYLES = {
  color: '#FFF',
  quoteFormStyles: {
    backgroundColor: '#151B22',
    contentBg: '#2C3238',
    titleColor: '##FFF',
    descColor: 'rgba(255, 255, 255, 0.60)',
    labelColor: '#FFF',
    badgeBg: '#FF5F00',
    badgeColor: '#FFF',
    radioActive: 'rgba(255, 255, 255, 0.19)',
    tandcColor: 'rgba(255, 255, 255, 0.40);',
    buttonBackground: '#FF5F00',
    buttonColor: '#FFF',
  },
  comparisonTableStyles: {
    backgroundColor: '#151B22',
    titleColor: '#FFF',
    descColor: 'rgba(255, 255, 255, 0.60)',
    contentRowBackground: '#D9D9D9',
    contentCellBackground: '#151B22',
    headingBackground: 'rgba(255, 255, 255, 0.20)',
    badgeBackground: '#FF5F00',
    badgeTextColor: '#FFF',
    noteTextColor: 'rgba(255, 255, 255, 0.40)',
    bottomTextColor: 'rgba(255, 255, 255, 0.60)',
  },
  insurerLogoStyles: {
    bacgroundColor: '#304352',
    titleColor: '#FFF',
  },
  easyScoreStyles: {
    bacgroundColor: '#151B22',
    titleColor: '#FFF',
    itemBackgroundColor: 'rgba(255, 255, 255, 0.10)',
    iconBgColor: '#FF5F00',
    iconTextColor: '#FFF',
    itemTextColor: '#FFF',
    itemDescColor: 'rgba(255, 255, 255, 0.60)',
  },
  benefitStyles: {
    bacgroundColor: '#304352',
    titleColor: '#FFF',
    iCarTextColor: '#FE6C04',
    itemTitleColor: '#FFF',
    itemDescColor: 'rgba(255, 255, 255, 0.60)',
    itemBackgroundColor: 'rgba(255, 255, 255, 0.10)',
  },
  faqStyles: {
    bacgroundColor: '#151B22',
    iconColor: '#FFFFFF',
    questionColor: '#FFF',
    answerColor: 'rgba(255, 255, 255, 0.60)',
  },
  quoteRedirectStyles: {
    backgroundColor: '#304352',
    buttonBackground: '#FF5F00',
    buttonColor: '#FFF',
  },
};

export const FAQ_DATA = [
  {
    question: 'Who is Igloo',
    answer:
      'Our goal is providing access to insurance for all. Igloo creates insurance solutions for platform and insurance companies by leveraging on big data, real-time risk assessment, and end-to-end automated claims management.\n' +
      'Our insurance solutions enable businesses to eliminate operational risk, generate new revenue streams, and optimize and improve existing products and services.In Indonesia, we collaborate with over 50 well - known brand names in a variety of industries including insurance, telecommunications, e- commerce, hospitality, health technology, and financial services.',
  },
  {
    question: 'What is type 1 car insurance suitable for?',
    answer:
      'Type 1 car in surance covers damage to the insured vehicle regardless of fault in case of accident, theft, fire, and natural disasters. No other vehicle or party has to be involved in the accident. It is suitable for those who want the most comprehensive cover and maximum protection.',
  },
  {
    question: 'What is type 2+ car insurance suitable for?',
    answer:
      'Type 2+  car insurance covers damage to the insured vehicle regardless of the fault, provided you can identify another vehicle that has been involved in the accident, theft, fire or flood. It also covers liability to third parties when the driver is at fault. For example, third parties can include property damage or bodily damage.\n' +
      'It is suitable for people who want more coverage than type 2 but less than type 1. It provides protection for the most common issues drivers may encounter. It is a good option for those seeking a balance between cost and coverage.',
  },
  {
    question: 'What is type 2 car insurance suitable for?',
    answer:
      'Covers damage to the insured vehicle only in case of fire and theft. And covers liability to third parties (property damage and bodily injury) when the driver is at fault. It does not cover natural disasters, including floods, unless specified in the policy.',
  },
  {
    question: 'What is type 3+ car insurance suitable for?',
    answer:
      'Covers damage to the insured vehicle regardless of fault, provided another identified vehicle has been involved in the accident. If the driver is at fault, it will also extend coverage to thrid parties, for example property damage and bodily injury. This insurance product does not cover theft, fire, flood or terrorism, which are typically covereged in Type2+ and Type 1.\n' +
      'This insurance option is good for confident and careful drivers who want essential prodction from finacial losses in case of an accident as well as coverage for medical expenses for themseleves and their passengers when injured in an accident.',
  },
  {
    question: 'What is type 3 car insurance suitable for?',
    answer:
      'Type 3 car insurance only covers liability to third parties, for example property damage and bodily injury, when the driver is at fault. It is considered the most common type of insurance in Thailand, and the most affordable and this is due to its limited coverage',
  },
  {
    question:
      'Is car insurance mandatory in Thailand? (include link to the compulsory car insurance act or at least a reliable source that explains the act)',
    answer:
      'In Thailand, the law says you must have Act of Legislation for your vehicle. This insurance pays for some basic elements like if someone gets hurt in your car or if your car hurts someone else. The insurance coverage is very limited though.',
  },
  {
    question: 'What is voluntary car insurance?',
    answer:
      'There are varying levels of voluntary insurance depending on what level your purchase. You can be covered for damages to the insured vehicle whether the drivers is at fault or not, or you may be covered for damage to third party vehicle if the driver of the insured vehicle is at fault.',
  },
];

export const BENEFIT_DATA = [
  {
    title: 'Dedicated customer support',
    description:
      'Igloo has a team of customer service reps that will help you every step of the way. From purchasing the best car insurance plan and getting it approved promptly, to answering your questions around claims and managing your policy, our friendly customer support is here to assist.',
  },
  {
    title: 'Simple purchase flow',
    description:
      "Enjoy a hassle-free insurance purchasing process! Simply provide your car's details and select your preferred insurance type. Our customer relations team is ready to offer expert advice to help you find the perfect coverage for your vehicle. Get insured quickly and affordably!",
  },
  {
    title: 'Affordable prices, best plans',
    description:
      ' and Igloo constantly works with our insurers to provide the best car insurance plans at affordable prices for all car brands and models. Our priority is to give you the most value and satisfaction along with your car bought at ',
  },
];

export const EASY_SCORE_ITEM_DATA = [
  {
    id: 1,
    title: 'Fill out quotation form',
    desc:
      'Provide your personal details, car brand, model and year of manufacture to receive a quote within 1 day.',
  },
  {
    id: 2,
    title: 'Select your car insurance plan',
    desc:
      'Tell our sales team the car insurance plan you want and make payment online.',
  },
  {
    id: 3,
    title: 'Get covered within 3-7 days',
    desc:
      'Once approved by insurer, which typically takes 3-7 days, we will notify you and issue the policy so you can drive with peace of mind!',
  },
];

export const CUSTOM_BADGE_STYLE: React.CSSProperties = {
  position: 'absolute',
  top: '-10px',
  right: '-1px',
  width: 'auto',
  padding: '0px 7px',
  height: '20px',
  textAlign: 'center',
  borderRadius: '50px 50px 0px 50px',
  transition: 'none',
  fontFamily: 'Roboto',
};

export const CUSTOM_BADGE_STYLE_MOBILE: React.CSSProperties = {
  fontSize: '8px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '10px',
  textTransform: 'capitalize',
  position: 'absolute',
  top: '0px',
  right: '0px',
  left: '0px',
  height: '14px',
  textAlign: 'center',
  borderRadius: '7px 7px 0px 0px',
  transition: 'none',
  padding: '0px 6px',
  width: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Roboto',
};

export const HELP_TEXT: React.CSSProperties = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '150%',
  textTransform: 'capitalize',
  textDecoration: 'underline',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
  fontFamily: 'Roboto',
};

export const INSURANCE_TYPES = [
  { label: '1', value: '1', extra: 'Comprehensive' },
  { label: '2+', value: '2+', extra: 'Popular Choice' },
  { label: '2', value: '2' },
  { label: '3+', value: '3+' },
];

export const TH_LOCALE = 'th-TH';
export const EN_LOCALE = 'en-EN';
export const USER_ID = 'userID';
export const ONE_2_CAR_EN = 'one2carEN';
export const ONE_2_CAR_TH = 'one2carTH';

export const FORM_FIELD_NAMES = {
  NAME: 'name',
  NUMBER: 'number',
  EMAIL: 'email',
  INSURANCE_TYPE: 'insuranceType',
  MODEL: 'model',
  YEAR: 'year',
};

export const DEFAULT_YEAR_OPTIONS = {
  year_begin: 2000,
  year_end: 2024,
};

export const INDETERMINATE_BRAND = 'Indeterminate Brand';
