import styles from './index.less';
import { useIntl } from 'react-intl';
import { Form, Row, Col } from 'antd';
import { Button, Input, RadioGroup, Select } from 'iglooform';
import {
  AUTO_SPIN,
  CUSTOM_BADGE_STYLE,
  CUSTOM_BADGE_STYLE_MOBILE,
  EN_LOCALE,
  HELP_TEXT,
  INSURANCE_TYPES,
  ONE_CAR,
  TH_LOCALE,
} from '@/constants/index';
import { ApiPayload, MyObject, QuoteTheme } from 'typings';
import { useEffect, useMemo, useState } from 'react';
import {
  fieldChangeEvent,
  getApiPayload,
  getDocURL,
  getGALeadsData,
  getGASource,
  getNotFoundLogo,
  getYearOptions,
} from '@/utils/common';
import SuccessModal from '../success-modal';
import SuccessDrawer from '../success-drawer';
import { updateQuoteData } from '@/services/request';
import CustomSelect from '../custom-select';
import classNames from 'classnames';
import CustomTextInput from '../form-components/custom-input';
import InputMask from 'react-input-mask';
import { trackEvent } from '@/utils/ga';
import { FORM_FIELD_NAMES } from '@/constants/index';

const { EMAIL, NUMBER, INSURANCE_TYPE, MODEL, YEAR } = FORM_FIELD_NAMES;

const InitialValues = {
  name: '',
  number: '',
  insuranceType: '2+',
  year: null,
  model: null,
  email: '',
};

const QuoteForm: React.FC<{
  theme: QuoteTheme;
  source: string;
  closeIcon: string;
}> = ({ theme, source, closeIcon }) => {
  const { locale } = useIntl();
  const site = getGASource(source, locale);
  const [radioOption, setRadioOption] = useState('2+');
  const [yearOptions, setYearOptions] = useState<
    { label: number; value: number }[] | any
  >(getYearOptions());
  const [searchVal, setSearchVal] = useState('');
  const [searchYearOption, setSearchYearOption] = useState<any>(yearOptions);
  const [yearSearchVal, setYearSearchVal] = useState('');
  const [modelDetails, setModelDetails] = useState<MyObject | any>();
  const [error, setError] = useState<string[] | any>('');
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const isEmail = source === ONE_CAR && locale === EN_LOCALE;
  const [openModal, setOpenModal] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<any>({});
  const handleSubmit = async (values: any) => {
    const payload: ApiPayload = getApiPayload(values, source, modelDetails);
    const leadsData = getGALeadsData(payload?.data) || {};
    trackEvent('submit_quote', {
      Site: site,
      ...leadsData,
    });
    try {
      const res = await updateQuoteData(payload);
      if (res?.ok) {
        setOpenModal(true);
        form.resetFields();
        setRadioOption('2+');
        setYearOptions(getYearOptions());
        form.setFieldValue(INSURANCE_TYPE, '2+');
      }
    } catch (e) {
      console.error('error while submitting data', e);
    }
  };

  useEffect(() => {
    setSearchYearOption(yearOptions);
  }, [yearOptions]);

  const highlightMatchedText = (text: any, inputValue: any) => {
    const index = text.toLowerCase().indexOf(inputValue.toLowerCase());
    if (index === -1) {
      return text;
    }
    return (
      <span>
        {text.substring(0, index)}
        <span className="matched-text" style={{ color: theme.badgeBg }}>
          {text.substring(index, index + inputValue.length)}
        </span>
        {text.substring(index + inputValue.length)}
      </span>
    );
  };

  const notFoundIcon = useMemo(() => getNotFoundLogo(source), [source]);
  const NotFoundContent = () => (
    <div className={styles.notFound}>
      <img src={notFoundIcon} alt="No Results" style={{ fill: 'blue' }} />
      <div className={styles.text}>
        {formatMessage({ id: 'No search result' })}
      </div>
    </div>
  );

  const tandcDocument = useMemo(() => getDocURL(locale), [locale]);
  const handleRedirect = () => {
    trackEvent('dont_know_button_click', {
      Site: site,
      buttonName: 'Dont know what type you should get?',
    });
    const targetSection = document?.getElementById('comparisonTable');
    if (targetSection) {
      targetSection?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const handleFocus = (e: any) => {
    const selector = e?.target?.closest('.ant-select-selector');
    if (selector) {
      selector.style.borderColor =
        source === AUTO_SPIN ? theme.badgeBg : theme.labelColor;
    }
    const input = e?.target?.closest('.ant-input-outlined');
    if (input) {
      input.style.borderColor =
        source === AUTO_SPIN ? theme.badgeBg : theme.labelColor;
    }
  };

  const handleBlur = (e: any) => {
    const selector = e?.target?.closest('.ant-select-selector');
    if (selector) {
      selector.style.borderColor = '';
    }
    const input = e?.target?.closest('.ant-input-outlined');
    if (input) {
      input.style.borderColor = '';
    }
  };

  const customClass = !fieldErrors?.year?.length
    ? source === AUTO_SPIN
      ? styles.customizedDark
      : styles.customizedLight
    : '';

  const handlePolicyClick = () => {
    trackEvent('Privacy_notice_&_policy_button_click', {
      Site: site,
      buttonName: 'Privacy notice and policy',
    });
  };

  const handleUpdateSearchOption = (value: any) => {
    if (!!value) {
      const filteredOptions = searchYearOption.filter((option: any) =>
        option.label.includes(value),
      );
      if (filteredOptions?.length <= 0) {
        const date = new Date();
        const currentYear = date.getFullYear();
        const thaiYear = Number(date.getFullYear()) + 543;
        let newOption =
          Number(value) <= date.getFullYear()
            ? `${Number(value)} / ${Number(value) + 543}`
            : Number(value) >= currentYear && Number(value) <= thaiYear
            ? `${Number(value) - 543} / ${Number(value)}`
            : value;
        if (isNaN(Number(value)) && value?.split('')?.length > 4) {
          setSearchYearOption([{ label: value, value: value }, ...yearOptions]);
        } else {
          setSearchYearOption([
            { label: newOption, value: newOption },
            ...yearOptions,
          ]);
        }
      }
    } else {
      setSearchYearOption(yearOptions);
    }
  };

  return (
    <div
      className={styles.content}
      style={{ background: theme.backgroundColor }}
      id="quoteForm"
    >
      <div className={styles.container} style={{ background: theme.contentBg }}>
        <div className={styles.header}>
          <div className={styles.title} style={{ color: theme.titleColor }}>
            {formatMessage({ id: 'Get a quote for car insurance' })}
          </div>
          <div className={styles.desc} style={{ color: theme.descColor }}>
            {formatMessage({
              id:
                'Complete your details and we will get back to you with a quote',
            })}
          </div>
        </div>
        <div className={styles.form}>
          <Form
            form={form}
            initialValues={InitialValues}
            layout="vertical"
            onFinish={handleSubmit}
            onFinishFailed={() => {
              const errors = form
                .getFieldsError()
                ?.map((item: any) => ({ [item?.name?.[0]]: item?.errors }))
                ?.reduce((acc, curr) => Object.assign(acc, curr), {});
              setFieldErrors({ ...errors });
              setError(form.getFieldError(NUMBER));
            }}
          >
            <Row gutter={16}>
              <Col span={isMobile ? 24 : isEmail ? 8 : 12}>
                <CustomTextInput
                  source={source}
                  handleBlur={handleBlur}
                  handleFocus={handleFocus}
                  form={form}
                  theme={theme}
                  setFieldErrors={setFieldErrors}
                  fieldErrors={fieldErrors}
                  site={site}
                />
              </Col>
              <Col span={isMobile ? 24 : isEmail ? 8 : 12}>
                <Form.Item
                  name={NUMBER}
                  label={
                    <span style={{ color: theme.labelColor, fontSize: '16px' }}>
                      {formatMessage({
                        id: 'Phone number',
                      })}
                    </span>
                  }
                  className={classNames(
                    styles.customNumberInput,
                    !error.length
                      ? source === AUTO_SPIN
                        ? styles.customizedDarkPhoneNumber
                        : styles.customizedLightPhoneNumber
                      : '',
                  )}
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        const regex = /^\d{3}-\d{3}-\d{4}$/;
                        if (!regex.test(value) || !value) {
                          setError(['error']);
                          return Promise.reject(
                            formatMessage({
                              id: 'Please enter the proper phone number',
                            }),
                          );
                        }
                        setError([]);
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <div
                    style={{
                      ...(error.length && {
                        border: '1px solid #FF3420',
                      }),
                    }}
                    className={styles.maskedInput}
                  >
                    <span className={styles.startAdornment}>+66</span>
                    <InputMask
                      mask="999-999-9999"
                      alwaysShowMask={false}
                      maskChar={null}
                      className={styles.input}
                      placeholder={formatMessage({ id: 'Phone number' })}
                      onBlur={(e) => {
                        if (e?.target?.value) {
                          const values = fieldChangeEvent(NUMBER, e, site);
                          trackEvent('phone_num_field', { ...values });
                        }
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              {isEmail && (
                <Col span={isMobile ? 24 : 8}>
                  <Form.Item
                    name={EMAIL}
                    label={
                      <span
                        style={{ color: theme.labelColor, fontSize: '16px' }}
                      >
                        {formatMessage({
                          id: 'Email address',
                        })}
                      </span>
                    }
                    rules={[
                      {
                        validator: (rule, value: string) => {
                          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                          if (!!value && emailRegex.test(value)) {
                            setFieldErrors((errors: any) => ({
                              ...errors,
                              EMAIL: [],
                            }));
                            return Promise.resolve();
                          }
                          setFieldErrors((errors: any) => ({
                            ...errors,
                            EMAIL: ['email'],
                          }));
                          return Promise.reject(
                            new Error(
                              formatMessage({
                                id: 'Enter a valid email address',
                              }),
                            ),
                          );
                        },
                      },
                    ]}
                    className={
                      !(fieldErrors?.email?.length > 0) ? styles.email : ''
                    }
                  >
                    <Input
                      type="email"
                      placeholder={formatMessage({
                        id: 'Please enter your email address',
                      })}
                      onBlur={(e) => {
                        handleBlur(e);
                        if (e?.target?.value) {
                          const values = fieldChangeEvent(EMAIL, e, site);
                          trackEvent('email_field', { ...values });
                        }
                      }}
                      onFocus={handleFocus}
                      className={styles.inputEmail}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name={INSURANCE_TYPE}
                  className={styles.selectCoverage}
                  label={
                    <div
                      className={styles.customLabel}
                      style={{ color: theme.labelColor, fontSize: '16px' }}
                    >
                      <span>
                        {formatMessage({
                          id: `${
                            isMobile
                              ? 'Type of insurance'
                              : 'What type of insurance are you after?'
                          }`,
                        })}
                      </span>
                      {!isMobile && (
                        <span
                          className={styles.helpText}
                          onClick={handleRedirect}
                        >
                          {formatMessage({
                            id: "Don't know what type you should get?",
                          })}
                        </span>
                      )}
                    </div>
                  }
                  help={
                    isMobile && (
                      <span
                        style={{
                          ...HELP_TEXT,
                          color: theme.labelColor,
                          marginBottom: '16px',
                        }}
                        onClick={handleRedirect}
                      >
                        {formatMessage({
                          id: 'Dont know what type you should get?',
                        })}
                      </span>
                    )
                  }
                >
                  <RadioGroup
                    onChange={(e) => {
                      setRadioOption(e?.target?.value);
                      const values = fieldChangeEvent(INSURANCE_TYPE, e, site);
                      trackEvent('type_of_insurance_field', { ...values });
                    }}
                    options={INSURANCE_TYPES.map((option) => ({
                      label: (
                        <div style={{ color: theme.labelColor }}>
                          {option.label}
                          {option.extra && (
                            <span
                              className={styles.label}
                              style={{
                                ...(isMobile
                                  ? CUSTOM_BADGE_STYLE_MOBILE
                                  : CUSTOM_BADGE_STYLE),
                                color: theme.badgeColor,
                                background: theme.badgeBg,
                              }}
                            >
                              {formatMessage({ id: option.extra })}
                            </span>
                          )}
                        </div>
                      ),
                      value: option.value,
                      style:
                        radioOption === option.value
                          ? {
                              backgroundColor: theme.radioActive,
                              border: `1px solid ${theme.titleColor}`,
                            }
                          : {
                              border: '1px solid #EEE',
                            },
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={isMobile ? 24 : 12}>
                <Form.Item
                  name={MODEL}
                  label={
                    <span style={{ color: theme.labelColor, fontSize: '16px' }}>
                      {formatMessage({
                        id: `${
                          isMobile
                            ? 'Car brand and model'
                            : 'Type your car brand and model'
                        }`,
                      })}
                    </span>
                  }
                  className={styles.customSelect}
                  rules={[
                    {
                      required: true,
                      validator: (_: any, value) => {
                        if (!value) {
                          setFieldErrors((errors: any) => ({
                            ...errors,
                            MODEL: ['true'],
                          }));
                          return Promise.reject(
                            formatMessage({
                              id: 'Type your car brand and model',
                            }),
                          );
                        }
                        setFieldErrors((errors: any) => ({
                          ...errors,
                          MODEL: [],
                        }));
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <CustomSelect
                    source={source}
                    form={form}
                    setModelDetails={setModelDetails}
                    setYearOptions={setYearOptions}
                    setSearchVal={setSearchVal}
                    searchVal={searchVal}
                    highlightMatchedText={highlightMatchedText}
                    handleBlur={handleBlur}
                    handleFocus={handleFocus}
                    setFieldErrors={setFieldErrors}
                    fieldErrors={fieldErrors}
                    site={site}
                  />
                </Form.Item>
              </Col>
              <Col span={isMobile ? 24 : 12}>
                <Form.Item
                  name={YEAR}
                  label={
                    <span style={{ color: theme.labelColor, fontSize: '16px' }}>
                      {formatMessage({
                        id: 'Year of manufacture',
                      })}
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      validator: (_: any, value) => {
                        if (!value) {
                          setFieldErrors((errors: any) => ({
                            ...errors,
                            YEAR: ['true'],
                          }));
                          return Promise.reject(
                            formatMessage({
                              id: 'Type your car brand and model',
                            }),
                          );
                        }
                        setFieldErrors((errors: any) => ({
                          ...errors,
                          YEAR: [],
                        }));
                        return Promise.resolve();
                      },
                    },
                  ]}
                  className={styles.yearDropDown}
                >
                  <Select
                    placeholder={formatMessage({
                      id: 'Please select the year of manufacture',
                    })}
                    showSearch
                    getPopupContainer={(trigger: any) => trigger?.parentNode}
                    options={searchYearOption}
                    notFoundContent={<NotFoundContent />}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className={classNames(styles.year, customClass)}
                    onChange={(v, option) => {
                      const values = fieldChangeEvent(YEAR, v, site);
                      trackEvent('yom_field', { ...values });
                      form.setFieldsValue({ YEAR: v });
                    }}
                    optionRender={(option) => {
                      return highlightMatchedText(option.label, yearSearchVal);
                    }}
                    onSearch={(value) => {
                      setYearSearchVal(value);
                      if (value.length <= 4 && /^\d*( \/ \d*)?$/.test(value)) {
                        handleUpdateSearchOption(value);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className={styles.submitContainer}>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.submit}
                style={{
                  background: theme.buttonBackground,
                  color: theme.buttonColor,
                }}
              >
                {locale === EN_LOCALE
                  ? 'Submit quote'
                  : formatMessage({ id: 'Submit quote button' })}
              </Button>
            </div>
          </Form>
          <div className={styles.tandc} style={{ color: theme.tandcColor }}>
            {locale === EN_LOCALE && (
              <span>
                {formatMessage({
                  id: 'By clicking submit quote, you agree to the',
                })}
                &nbsp;
              </span>
            )}
            <span
              style={{
                ...(locale === TH_LOCALE && {
                  textAlign: 'center',
                }),
              }}
            >
              <a
                href={tandcDocument || ''}
                target="_blank"
                onClick={handlePolicyClick}
                style={locale === TH_LOCALE ? { textDecoration: 'none' } : {}}
              >
                {formatMessage({ id: 'privacy notice and policy' })}
              </a>
            </span>
          </div>
        </div>
      </div>
      {isMobile ? (
        <SuccessDrawer
          openModal={openModal}
          setOpenModal={setOpenModal}
          closeIcon={closeIcon}
        />
      ) : (
        <SuccessModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          closeIcon={closeIcon}
        />
      )}
    </div>
  );
};

export default QuoteForm;
