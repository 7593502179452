import styles from './index.less';
import banner from '@/assets/desktop/BannerImage.png'
import mobileBanner from '@/assets/mobile/BannerMobile.png';
import { AUTO_SPIN } from '@/constants';
import { useIntl } from 'react-intl';

const HeroBanner: React.FC<{ source?: string }> = ({ source }) => {
  const { formatMessage } = useIntl();
  return (
    <div
      className={styles.content}
      style={{ ...(source === AUTO_SPIN && { background: '#151B22' }) }}
    >
      <div className={styles.textOverlay}>
        <div className={styles.title}>
          {formatMessage({ id: 'Car insurance' })}
        </div>
        <div className={styles.desc}>
          {formatMessage({
            id: 'Protect yourself and your passengers on the road and drive with peace of mind.',
          })}
        </div>
      </div>
      <img
        src={isMobile ? mobileBanner : banner}
        alt="Banner Image"
        height={isMobile ? '320' : '400'}
      />
    </div>
  );
};

export default HeroBanner;
