import { QuoteRedirectionStyles } from 'typings';
import styles from './index.less';
import { Button } from 'iglooform';
import { useIntl } from 'react-intl';
import { trackEvent } from '@/utils/ga';
import { getGASource } from '@/utils/common';

const QuoteRedirect: React.FC<QuoteRedirectionStyles> = ({ theme, source }) => {
  const { formatMessage, locale } = useIntl();

  const handleRedirect = () => {
    const targetSection = document.getElementById('quoteForm');
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
    trackEvent('request_quote_CTA', {
      Site: getGASource(source, locale),
      button_placement: 'footer',
    });
  };

  return (
    <div
      className={styles.content}
      style={{ background: theme.backgroundColor }}
    >
      <div className={styles.container}>
        <div className={styles.text}>
          {formatMessage({ id: 'Get a quote for the best car insurance' })}
        </div>
        <Button
          className={styles.submitButton}
          style={{
            background: theme.buttonBackground,
            color: theme.buttonColor,
          }}
          onClick={handleRedirect}
        >
          {formatMessage({ id: 'Request quote' })}
        </Button>
      </div>
    </div>
  );
};

export default QuoteRedirect;
