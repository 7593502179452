import { AUTO_SPIN, FAQ_DATA } from '@/constants';
import styles from './index.less';
import { Collapse } from 'antd';
import { ArrowDownFilled } from 'iglooicon';
import { Typography } from 'iglooform';
import { useIntl } from 'react-intl';
import { trackEvent } from '@/utils/ga';
import { getGASource } from '@/utils/common';

const { Panel } = Collapse;

const FAQ: React.FC<{
  theme: {
    iconColor: string;
    answerColor: string;
    bacgroundColor: string;
    questionColor: string;
  };
  source: string;
}> = ({ theme, source }) => {
  const { formatMessage, locale } = useIntl();
  return (
    <div
      className={styles.content}
      style={{ background: theme?.bacgroundColor }}
    >
      <div className={styles.container}>
        <div className={styles.title}>
          {formatMessage({
            id: 'Frequently asked questions about car insurance',
          })}
        </div>
        <Collapse
          accordion
          ghost
          expandIconPosition="end"
          onChange={(e) => {
            const index = e?.length > 0 ? e[0] : '';
            if (!!index) {
              trackEvent('FAQ_Section', {
                Site: getGASource(source, locale),
                FAQ_num: Number(index) + 1,
              });
            }
          }}
          expandIcon={({ isActive }) => (
            <ArrowDownFilled
              style={{
                fontSize: 24,
                color: theme.iconColor,
                transform: `translateY(-50%) rotate(${
                  isActive ? '180deg' : 0
                })`,
                marginBottom: isMobile ? '-24px' : '-18px',
              }}
            />
          )}
        >
          {FAQ_DATA?.map(({ question, answer }, index) => (
            <Panel
              className={styles.collapseItem}
              key={index}
              header={
                <Typography
                  className={styles.question}
                  level="number2"
                  wrapElement="div"
                  style={{ color: theme.questionColor }}
                >
                  {formatMessage({ id: question })}
                </Typography>
              }
              style={{
                ...(source === AUTO_SPIN && {
                  borderColor: 'rgba(238, 238, 238, 0.2)',
                }),
              }}
            >
              <Typography
                className={styles.answer}
                level={'h4'}
                wrapElement="div"
                style={{ color: theme.answerColor }}
              >
                {formatMessage({ id: answer })}
              </Typography>
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default FAQ;
