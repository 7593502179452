export const MODEL_OPTIONS = [
  {
    brand: 'TOYOTA',
    model: 'COROLLA',
    name: 'TOYOTA COROLLA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'ALPHARD',
    name: 'TOYOTA ALPHARD',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'YARIS',
    name: 'TOYOTA YARIS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'VELOZ',
    name: 'TOYOTA VELOZ',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'LANDCRUISER',
    name: 'TOYOTA LANDCRUISER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'GR86',
    name: 'TOYOTA GR86',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'FORTUNER',
    name: 'TOYOTA FORTUNER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'YARIS ATIV',
    name: 'TOYOTA YARIS ATIV',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'BZ4X',
    name: 'TOYOTA BZ4X',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'VOXY',
    name: 'TOYOTA VOXY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'GR COROLLA',
    name: 'TOYOTA GR COROLLA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'PASEO',
    name: 'TOYOTA PASEO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'CROWN',
    name: 'TOYOTA CROWN',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'NOAH',
    name: 'TOYOTA NOAH',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'CELICA',
    name: 'TOYOTA CELICA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'GRANVIA',
    name: 'TOYOTA GRANVIA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'RAV4',
    name: 'TOYOTA RAV4',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'SUPRA',
    name: 'TOYOTA SUPRA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'ESTIMA',
    name: 'TOYOTA ESTIMA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'SURF',
    name: 'TOYOTA SURF',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'IPSUM',
    name: 'TOYOTA IPSUM',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'SOLUNA',
    name: 'TOYOTA SOLUNA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'SPORT RIDER',
    name: 'TOYOTA SPORT RIDER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'STATION WAGON',
    name: 'TOYOTA STATION WAGON',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'ARISTO',
    name: 'TOYOTA ARISTO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'HARRIER',
    name: 'TOYOTA HARRIER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'GAIA',
    name: 'TOYOTA GAIA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'REGIUS',
    name: 'TOYOTA REGIUS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'WISH',
    name: 'TOYOTA WISH',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'AVANZA',
    name: 'TOYOTA AVANZA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'VANGARD',
    name: 'TOYOTA VANGARD',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'BB',
    name: 'TOYOTA BB',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'VELLFIRE',
    name: 'TOYOTA VELLFIRE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'KLUGER',
    name: 'TOYOTA KLUGER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'FJ',
    name: 'TOYOTA FJ',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'ALTEZZA',
    name: 'TOYOTA ALTEZZA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'FT-86',
    name: 'TOYOTA FT-86',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'GT86',
    name: 'TOYOTA GT86',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'FT86',
    name: 'TOYOTA FT86',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'ESQUIRE',
    name: 'TOYOTA ESQUIRE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'SIENTA',
    name: 'TOYOTA SIENTA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TOYOTA',
    model: 'MAJESTY',
    name: 'TOYOTA MAJESTY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'WR-V',
    name: 'HONDA WR-V',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'CITY',
    name: 'HONDA CITY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'STEP WAGON',
    name: 'HONDA STEP WAGON',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'INTEGRA',
    name: 'HONDA INTEGRA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'JAZZ',
    name: 'HONDA JAZZ',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'CROSSROAD',
    name: 'HONDA CROSSROAD',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'ODYSSEY',
    name: 'HONDA ODYSSEY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'BRIO',
    name: 'HONDA BRIO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'INSIGHT',
    name: 'HONDA INSIGHT',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'CR-Z',
    name: 'HONDA CR-Z',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'BRIO AMAZE',
    name: 'HONDA BRIO AMAZE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'MOBILIO',
    name: 'HONDA MOBILIO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'BR-V',
    name: 'HONDA BR-V',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'CIVIC',
    name: 'HONDA CIVIC',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'CITY HYBRID',
    name: 'HONDA CITY HYBRID',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HONDA',
    model: 'N BOX',
    name: 'HONDA N BOX',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'SUNNY',
    name: 'NISSAN SUNNY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'SENTRA',
    name: 'NISSAN SENTRA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'KICKS',
    name: 'NISSAN KICKS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'ALMERA',
    name: 'NISSAN ALMERA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'PRESEA',
    name: 'NISSAN PRESEA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: '200',
    name: 'NISSAN 200',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: '300',
    name: 'NISSAN 300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'BLUEBIRD',
    name: 'NISSAN BLUEBIRD',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'BLUEBIRD SSS',
    name: 'NISSAN BLUEBIRD SSS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'NV',
    name: 'NISSAN NV',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'NX',
    name: 'NISSAN NX',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'SERENA',
    name: 'NISSAN SERENA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'TERRANO',
    name: 'NISSAN TERRANO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'XCITER',
    name: 'NISSAN XCITER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'TIIDA',
    name: 'NISSAN TIIDA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'TIIDA 5 DOORS',
    name: 'NISSAN TIIDA 5 DOORS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: '350',
    name: 'NISSAN 350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'MURANO',
    name: 'NISSAN MURANO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'ELGRAND',
    name: 'NISSAN ELGRAND',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'MARCH',
    name: 'NISSAN MARCH',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'CUBE',
    name: 'NISSAN CUBE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'JUKE',
    name: 'NISSAN JUKE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'LEAF',
    name: 'NISSAN LEAF',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'SYLPHY',
    name: 'NISSAN SYLPHY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'PULSAR',
    name: 'NISSAN PULSAR',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'LIVINA',
    name: 'NISSAN LIVINA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NISSAN',
    model: 'NOTE',
    name: 'NISSAN NOTE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUBISHI',
    model: 'CHAMP',
    name: 'MITSUBISHI CHAMP',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUBISHI',
    model: 'LANCER',
    name: 'MITSUBISHI LANCER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUBISHI',
    model: 'MIRAGE',
    name: 'MITSUBISHI MIRAGE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUBISHI',
    model: 'ATTRAGE',
    name: 'MITSUBISHI ATTRAGE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUBISHI',
    model: 'PAJERO',
    name: 'MITSUBISHI PAJERO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUBISHI',
    model: 'EVOLUTION',
    name: 'MITSUBISHI EVOLUTION',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUBISHI',
    model: 'STRADA',
    name: 'MITSUBISHI STRADA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUBISHI',
    model: 'OUT LANDER',
    name: 'MITSUBISHI OUT LANDER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUBISHI',
    model: 'PAJERO SPORT',
    name: 'MITSUBISHI PAJERO SPORT',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUBISHI',
    model: 'DELICA',
    name: 'MITSUBISHI DELICA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUBISHI',
    model: 'XPANDER',
    name: 'MITSUBISHI XPANDER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'ISUZU',
    model: 'TROOPER',
    name: 'ISUZU TROOPER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'ISUZU',
    model: 'SURF',
    name: 'ISUZU SURF',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MAZDA',
    model: '323',
    name: 'MAZDA 323',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MAZDA',
    model: '2 5DOORS',
    name: 'MAZDA 2 5DOORS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MAZDA',
    model: '2 4DOORS',
    name: 'MAZDA 2 4DOORS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MAZDA',
    model: '3 5DOORS',
    name: 'MAZDA 3 5DOORS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MAZDA',
    model: '3',
    name: 'MAZDA 3',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MAZDA',
    model: '929',
    name: 'MAZDA 929',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MAZDA',
    model: 'MX',
    name: 'MAZDA MX',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MAZDA',
    model: 'MPV',
    name: 'MAZDA MPV',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MAZDA',
    model: '3 4DOORS',
    name: 'MAZDA 3 4DOORS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MAZDA',
    model: '2',
    name: 'MAZDA 2',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MAZDA',
    model: 'CX-9',
    name: 'MAZDA CX-9',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MAZDA',
    model: 'CX-7',
    name: 'MAZDA CX-7',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MAZDA',
    model: 'BIANTE',
    name: 'MAZDA BIANTE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '316',
    name: 'BMW 316',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '318',
    name: 'BMW 318',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '320',
    name: 'BMW 320',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: 'X1',
    name: 'BMW X1',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: 'ALPINA',
    name: 'BMW ALPINA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: 'M760',
    name: 'BMW M760',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: 'X5',
    name: 'BMW X5',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '750',
    name: 'BMW 750',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '325',
    name: 'BMW 325',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '520',
    name: 'BMW 520',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '523',
    name: 'BMW 523',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '525',
    name: 'BMW 525',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '528',
    name: 'BMW 528',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '530',
    name: 'BMW 530',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '535',
    name: 'BMW 535',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '730',
    name: 'BMW 730',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '740',
    name: 'BMW 740',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '735',
    name: 'BMW 735',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '323',
    name: 'BMW 323',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '330',
    name: 'BMW 330',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '728',
    name: 'BMW 728',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '760',
    name: 'BMW 760',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '745',
    name: 'BMW 745',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '645',
    name: 'BMW 645',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '120',
    name: 'BMW 120',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: 'X3',
    name: 'BMW X3',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '335',
    name: 'BMW 335',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '630',
    name: 'BMW 630',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '650',
    name: 'BMW 650',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '420',
    name: 'BMW 420',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '635',
    name: 'BMW 635',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '521',
    name: 'BMW 521',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '640',
    name: 'BMW 640',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '1M',
    name: 'BMW 1M',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '118',
    name: 'BMW 118',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '328',
    name: 'BMW 328',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: 'ACTIVE',
    name: 'BMW ACTIVE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '116',
    name: 'BMW 116',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: 'X4',
    name: 'BMW X4',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '220',
    name: 'BMW 220',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '428',
    name: 'BMW 428',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: 'I',
    name: 'BMW I',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '218',
    name: 'BMW 218',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '430',
    name: 'BMW 430',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: 'X2',
    name: 'BMW X2',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: '725',
    name: 'BMW 725',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: 'IX',
    name: 'BMW IX',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: 'M4',
    name: 'BMW M4',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BMW',
    model: 'M2',
    name: 'BMW M2',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '190E',
    name: 'BENZ 190E',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '200E',
    name: 'BENZ 200E',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '220E',
    name: 'BENZ 220E',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLC350',
    name: 'BENZ GLC350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'VITO',
    name: 'BENZ VITO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLS350',
    name: 'BENZ GLS350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLE53',
    name: 'BENZ GLE53',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E300',
    name: 'BENZ E300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'C220',
    name: 'BENZ C220',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '230CE',
    name: 'BENZ 230CE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '300CE',
    name: 'BENZ 300CE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '230E',
    name: 'BENZ 230E',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLK230',
    name: 'BENZ CLK230',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '250D',
    name: 'BENZ 250D',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'C200',
    name: 'BENZ C200',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '280E',
    name: 'BENZ 280E',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '280S',
    name: 'BENZ 280S',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '280SE',
    name: 'BENZ 280SE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '280SEL',
    name: 'BENZ 280SEL',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '300',
    name: 'BENZ 300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '300E',
    name: 'BENZ 300E',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '300SEL',
    name: 'BENZ 300SEL',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '300SL-24',
    name: 'BENZ 300SL-24',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '300TD',
    name: 'BENZ 300TD',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '300TE',
    name: 'BENZ 300TE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '380',
    name: 'BENZ 380',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '500SEC',
    name: 'BENZ 500SEC',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '500SEL',
    name: 'BENZ 500SEL',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '600SEL',
    name: 'BENZ 600SEL',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'C180',
    name: 'BENZ C180',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '300D',
    name: 'BENZ 300D',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'C230',
    name: 'BENZ C230',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'C240',
    name: 'BENZ C240',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'C280',
    name: 'BENZ C280',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E200',
    name: 'BENZ E200',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E220',
    name: 'BENZ E220',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E230',
    name: 'BENZ E230',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E280',
    name: 'BENZ E280',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'S280',
    name: 'BENZ S280',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'S320',
    name: 'BENZ S320',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'S500',
    name: 'BENZ S500',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'SC600',
    name: 'BENZ SC600',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'SL280',
    name: 'BENZ SL280',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'SL320',
    name: 'BENZ SL320',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '230TE',
    name: 'BENZ 230TE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLC300',
    name: 'BENZ GLC300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLS53',
    name: 'BENZ CLS53',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLC63',
    name: 'BENZ GLC63',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLA35',
    name: 'BENZ CLA35',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'S580',
    name: 'BENZ S580',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'A160',
    name: 'BENZ A160',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'ML320',
    name: 'BENZ ML320',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E240',
    name: 'BENZ E240',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CL230',
    name: 'BENZ CL230',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLS220',
    name: 'BENZ CLS220',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'V-CLASS',
    name: 'BENZ V-CLASS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'ML270',
    name: 'BENZ ML270',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'V230',
    name: 'BENZ V230',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLK240',
    name: 'BENZ CLK240',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E320',
    name: 'BENZ E320',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'V220',
    name: 'BENZ V220',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'S350',
    name: 'BENZ S350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E55',
    name: 'BENZ E55',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLK200',
    name: 'BENZ CLK200',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'ML350',
    name: 'BENZ ML350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E270',
    name: 'BENZ E270',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'SL350',
    name: 'BENZ SL350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'S55',
    name: 'BENZ S55',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'VIANO',
    name: 'BENZ VIANO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLS300',
    name: 'BENZ CLS300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'A170',
    name: 'BENZ A170',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'A200',
    name: 'BENZ A200',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '280',
    name: 'BENZ 280',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLS350',
    name: 'BENZ CLS350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLS500',
    name: 'BENZ CLS500',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'B180',
    name: 'BENZ B180',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'R350',
    name: 'BENZ R350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'ML280',
    name: 'BENZ ML280',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'A180',
    name: 'BENZ A180',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'R280',
    name: 'BENZ R280',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'S300',
    name: 'BENZ S300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLK280',
    name: 'BENZ CLK280',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CL500',
    name: 'BENZ CL500',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLC200',
    name: 'BENZ CLC200',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E350',
    name: 'BENZ E350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E250',
    name: 'BENZ E250',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E500',
    name: 'BENZ E500',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'ML300',
    name: 'BENZ ML300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'C250',
    name: 'BENZ C250',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E63',
    name: 'BENZ E63',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'S550',
    name: 'BENZ S550',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CL63',
    name: 'BENZ CL63',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLK320',
    name: 'BENZ CLK320',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GL350',
    name: 'BENZ GL350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLS250',
    name: 'BENZ CLS250',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'R300',
    name: 'BENZ R300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'ML420',
    name: 'BENZ ML420',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLS63',
    name: 'BENZ CLS63',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'G300',
    name: 'BENZ G300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'G350',
    name: 'BENZ G350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'R320',
    name: 'BENZ R320',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: '320',
    name: 'BENZ 320',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'ML250',
    name: 'BENZ ML250',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'B200',
    name: 'BENZ B200',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'A250',
    name: 'BENZ A250',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLS55',
    name: 'BENZ CLS55',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLA250',
    name: 'BENZ CLA250',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'A45',
    name: 'BENZ A45',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLA45',
    name: 'BENZ CLA45',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLA200',
    name: 'BENZ GLA200',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'S400',
    name: 'BENZ S400',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLA180',
    name: 'BENZ CLA180',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLA220',
    name: 'BENZ GLA220',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLA220',
    name: 'BENZ CLA220',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'SL300',
    name: 'BENZ SL300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'C300',
    name: 'BENZ C300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'V250',
    name: 'BENZ V250',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLA250',
    name: 'BENZ GLA250',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLE450',
    name: 'BENZ GLE450',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLE350',
    name: 'BENZ GLE350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLE250',
    name: 'BENZ GLE250',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'CLA200',
    name: 'BENZ CLA200',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLC250',
    name: 'BENZ GLC250',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'SL400',
    name: 'BENZ SL400',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'C350',
    name: 'BENZ C350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'ML',
    name: 'BENZ ML',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLA45',
    name: 'BENZ GLA45',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLE500',
    name: 'BENZ GLE500',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'C43',
    name: 'BENZ C43',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLE43',
    name: 'BENZ GLE43',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLC43',
    name: 'BENZ GLC43',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'SPRINTER',
    name: 'BENZ SPRINTER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'E53',
    name: 'BENZ E53',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'S560',
    name: 'BENZ S560',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLC220',
    name: 'BENZ GLC220',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLE300',
    name: 'BENZ GLE300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLB200',
    name: 'BENZ GLB200',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'GLA35',
    name: 'BENZ GLA35',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BENZ',
    model: 'G400',
    name: 'BENZ G400',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: '740',
    name: 'VOLVO 740',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: '760',
    name: 'VOLVO 760',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'EX30',
    name: 'VOLVO EX30',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'S 60',
    name: 'VOLVO S 60',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'S 90',
    name: 'VOLVO S 90',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'V 60',
    name: 'VOLVO V 60',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'XC 40',
    name: 'VOLVO XC 40',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'XC 60',
    name: 'VOLVO XC 60',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'XC 90',
    name: 'VOLVO XC 90',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: '440',
    name: 'VOLVO 440',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: '460',
    name: 'VOLVO 460',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: '850',
    name: 'VOLVO 850',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: '940',
    name: 'VOLVO 940',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: '960',
    name: 'VOLVO 960',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'S 40',
    name: 'VOLVO S 40',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'V 40',
    name: 'VOLVO V 40',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'S 70',
    name: 'VOLVO S 70',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'V 70',
    name: 'VOLVO V 70',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'S 80',
    name: 'VOLVO S 80',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'XC 70',
    name: 'VOLVO XC 70',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'C70',
    name: 'VOLVO C70',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'C30',
    name: 'VOLVO C30',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'V 50',
    name: 'VOLVO V 50',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'V 90',
    name: 'VOLVO V 90',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLVO',
    model: 'C 40',
    name: 'VOLVO C 40',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '205',
    name: 'PEUGEOT 205',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '3008',
    name: 'PEUGEOT 3008',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '306',
    name: 'PEUGEOT 306',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '405',
    name: 'PEUGEOT 405',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '605',
    name: 'PEUGEOT 605',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '504',
    name: 'PEUGEOT 504',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '505',
    name: 'PEUGEOT 505',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '406',
    name: 'PEUGEOT 406',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '206',
    name: 'PEUGEOT 206',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '607',
    name: 'PEUGEOT 607',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '307',
    name: 'PEUGEOT 307',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '807',
    name: 'PEUGEOT 807',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '407',
    name: 'PEUGEOT 407',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '1007',
    name: 'PEUGEOT 1007',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '207',
    name: 'PEUGEOT 207',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '308',
    name: 'PEUGEOT 308',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: 'BIPPER',
    name: 'PEUGEOT BIPPER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: 'RCZ',
    name: 'PEUGEOT RCZ',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '408',
    name: 'PEUGEOT 408',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '508',
    name: 'PEUGEOT 508',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '208',
    name: 'PEUGEOT 208',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '5008',
    name: 'PEUGEOT 5008',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PEUGEOT',
    model: '2008',
    name: 'PEUGEOT 2008',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HYUNDAI',
    model: 'COUPE',
    name: 'HYUNDAI COUPE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HYUNDAI',
    model: 'VELOSTER',
    name: 'HYUNDAI VELOSTER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HYUNDAI',
    model: 'IONIQ',
    name: 'HYUNDAI IONIQ',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'HYUNDAI',
    model: 'KONA',
    name: 'HYUNDAI KONA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'CARAVELLE',
    name: 'VOLKSWAGEN CARAVELLE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'TRANSPORTER',
    name: 'VOLKSWAGEN TRANSPORTER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'PASSAT',
    name: 'VOLKSWAGEN PASSAT',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'VENTO',
    name: 'VOLKSWAGEN VENTO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'GOLF',
    name: 'VOLKSWAGEN GOLF',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'SHARAN',
    name: 'VOLKSWAGEN SHARAN',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'BEETLE',
    name: 'VOLKSWAGEN BEETLE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'TIGUAN',
    name: 'VOLKSWAGEN TIGUAN',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'SCIROCCO',
    name: 'VOLKSWAGEN SCIROCCO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'TOUAREG',
    name: 'VOLKSWAGEN TOUAREG',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'POLO',
    name: 'VOLKSWAGEN POLO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'MULTIVAN',
    name: 'VOLKSWAGEN MULTIVAN',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'AMAROK',
    name: 'VOLKSWAGEN AMAROK',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'FASTBACK',
    name: 'VOLKSWAGEN FASTBACK',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'VOLKSWAGEN',
    model: 'ID BUZZ',
    name: 'VOLKSWAGEN ID BUZZ',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'FORD',
    model: 'ASPIRE',
    name: 'FORD ASPIRE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'FORD',
    model: 'FESTIVA',
    name: 'FORD FESTIVA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'FORD',
    model: 'RANGER RAPTOR',
    name: 'FORD RANGER RAPTOR',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'FORD',
    model: 'RANGER',
    name: 'FORD RANGER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'FORD',
    model: 'EXPLORER',
    name: 'FORD EXPLORER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'FORD',
    model: 'TERRITORY',
    name: 'FORD TERRITORY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'FORD',
    model: 'FIESTA',
    name: 'FORD FIESTA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'FORD',
    model: 'ECOSPORT',
    name: 'FORD ECOSPORT',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'FORD',
    model: 'MUSTANG',
    name: 'FORD MUSTANG',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'OPEL',
    model: 'OMEGA',
    name: 'OPEL OMEGA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'OPEL',
    model: 'VECTRA',
    name: 'OPEL VECTRA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'ZX',
    name: 'CITROEN ZX',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'XANTIA',
    name: 'CITROEN XANTIA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'XM',
    name: 'CITROEN XM',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'CX',
    name: 'CITROEN CX',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'EVASION',
    name: 'CITROEN EVASION',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'C5',
    name: 'CITROEN C5',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'C3',
    name: 'CITROEN C3',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'C8',
    name: 'CITROEN C8',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'BERLINGO',
    name: 'CITROEN BERLINGO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'XSARA',
    name: 'CITROEN XSARA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'C4',
    name: 'CITROEN C4',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'C6',
    name: 'CITROEN C6',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'DS3',
    name: 'CITROEN DS3',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'DS4',
    name: 'CITROEN DS4',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CITROEN',
    model: 'DS5',
    name: 'CITROEN DS5',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'DAIHATSU',
    model: 'MIRA',
    name: 'DAIHATSU MIRA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'DAIHATSU',
    model: 'TUK TUK',
    name: 'DAIHATSU TUK TUK',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'FIAT',
    model: 'TEMPRA',
    name: 'FIAT TEMPRA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'FIAT',
    model: '500',
    name: 'FIAT 500',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'FIAT',
    model: 'Abart',
    name: 'FIAT Abart',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'FIAT',
    model: 'DUCATO',
    name: 'FIAT DUCATO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUBARU',
    model: 'LEGACY',
    name: 'SUBARU LEGACY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUBARU',
    model: 'IMPREZA',
    name: 'SUBARU IMPREZA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUBARU',
    model: 'OUT BACK',
    name: 'SUBARU OUT BACK',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUBARU',
    model: 'TRIBECA',
    name: 'SUBARU TRIBECA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUBARU',
    model: 'EXIGA',
    name: 'SUBARU EXIGA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUBARU',
    model: 'BRZ',
    name: 'SUBARU BRZ',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUBARU',
    model: 'LEVORG',
    name: 'SUBARU LEVORG',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUBARU',
    model: 'WRX',
    name: 'SUBARU WRX',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUZUKI',
    model: 'CARIBIAN',
    name: 'SUZUKI CARIBIAN',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUZUKI',
    model: 'SWIFT',
    name: 'SUZUKI SWIFT',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUZUKI',
    model: 'XL7',
    name: 'SUZUKI XL7',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUZUKI',
    model: 'JIMNY',
    name: 'SUZUKI JIMNY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUZUKI',
    model: 'SX4',
    name: 'SUZUKI SX4',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUZUKI',
    model: 'ERTIGA',
    name: 'SUZUKI ERTIGA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUZUKI',
    model: 'CELERIO',
    name: 'SUZUKI CELERIO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUZUKI',
    model: 'CIAZ',
    name: 'SUZUKI CIAZ',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUZUKI',
    model: 'HUSTLER',
    name: 'SUZUKI HUSTLER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUZUKI',
    model: 'WAGON',
    name: 'SUZUKI WAGON',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SUZUKI',
    model: 'ERTIGA HYBRID',
    name: 'SUZUKI ERTIGA HYBRID',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: '100',
    name: 'AUDI 100',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: '80',
    name: 'AUDI 80',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'Q5',
    name: 'AUDI Q5',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'A4',
    name: 'AUDI A4',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'A6',
    name: 'AUDI A6',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'A3',
    name: 'AUDI A3',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'A8',
    name: 'AUDI A8',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'Q7',
    name: 'AUDI Q7',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'A5',
    name: 'AUDI A5',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'A1',
    name: 'AUDI A1',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'A7',
    name: 'AUDI A7',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'Q3',
    name: 'AUDI Q3',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'S3',
    name: 'AUDI S3',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'A',
    name: 'AUDI A',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'Q2',
    name: 'AUDI Q2',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'Q8',
    name: 'AUDI Q8',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'AUDI',
    model: 'E-TRON',
    name: 'AUDI E-TRON',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SAAB',
    model: '900',
    name: 'SAAB 900',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SAAB',
    model: '9000',
    name: 'SAAB 9000',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SAAB',
    model: '9-5',
    name: 'SAAB 9-5',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SAAB',
    model: '9-3',
    name: 'SAAB 9-3',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'ROVER',
    model: 'MINI',
    name: 'ROVER MINI',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'ROVER',
    model: '220',
    name: 'ROVER 220',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'ROVER',
    model: '623',
    name: 'ROVER 623',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'ROVER',
    model: '827',
    name: 'ROVER 827',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LAND ROVER',
    model: 'DISCOVERY',
    name: 'LAND ROVER DISCOVERY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LAND ROVER',
    model: 'RANGE ROVER',
    name: 'LAND ROVER RANGE ROVER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LAND ROVER',
    model: 'DEFENDER',
    name: 'LAND ROVER DEFENDER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LAND ROVER',
    model: 'FREE LANDER',
    name: 'LAND ROVER FREE LANDER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'ALFA',
    model: '155',
    name: 'ALFA 155',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'ALFA',
    model: '156',
    name: 'ALFA 156',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'ALFA',
    model: '147',
    name: 'ALFA 147',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'ALFA',
    model: 'BRERA',
    name: 'ALFA BRERA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'ALFA',
    model: '159',
    name: 'ALFA 159',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHEVROLET',
    model: 'OPTRA',
    name: 'CHEVROLET OPTRA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHEVROLET',
    model: 'TAHOE',
    name: 'CHEVROLET TAHOE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHEVROLET',
    model: 'LUMINA',
    name: 'CHEVROLET LUMINA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHEVROLET',
    model: 'AVEO',
    name: 'CHEVROLET AVEO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHEVROLET',
    model: 'CRUZE',
    name: 'CHEVROLET CRUZE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHEVROLET',
    model: 'SONIC',
    name: 'CHEVROLET SONIC',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHEVROLET',
    model: 'SPIN',
    name: 'CHEVROLET SPIN',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHRYSLER',
    model: 'CHEROKEE',
    name: 'CHRYSLER CHEROKEE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHRYSLER',
    model: 'GRAND CHEROKEE',
    name: 'CHRYSLER GRAND CHEROKEE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHRYSLER',
    model: 'NEON',
    name: 'CHRYSLER NEON',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHRYSLER',
    model: 'VOYAGER',
    name: 'CHRYSLER VOYAGER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHRYSLER',
    model: 'GRAND VOYAGER',
    name: 'CHRYSLER GRAND VOYAGER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHRYSLER',
    model: 'PT CRUISER',
    name: 'CHRYSLER PT CRUISER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHRYSLER',
    model: '300C',
    name: 'CHRYSLER 300C',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'KIA',
    model: 'PICANTO',
    name: 'KIA PICANTO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'KIA',
    model: 'RIO',
    name: 'KIA RIO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'KIA',
    model: 'STINGER',
    name: 'KIA STINGER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'KIA',
    model: 'SOUL',
    name: 'KIA SOUL',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'GS300',
    name: 'LEXUS GS300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'IS200',
    name: 'LEXUS IS200',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'LS430',
    name: 'LEXUS LS430',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'LM350H',
    name: 'LEXUS LM350H',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'ES',
    name: 'LEXUS ES',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'LS400',
    name: 'LEXUS LS400',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'LX470',
    name: 'LEXUS LX470',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'RX300',
    name: 'LEXUS RX300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'IS250',
    name: 'LEXUS IS250',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'RX450h',
    name: 'LEXUS RX450h',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'NX450H',
    name: 'LEXUS NX450H',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'RZ450E',
    name: 'LEXUS RZ450E',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'LS460',
    name: 'LEXUS LS460',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'RX350',
    name: 'LEXUS RX350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'RX400',
    name: 'LEXUS RX400',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'LS600hl',
    name: 'LEXUS LS600hl',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'RX270',
    name: 'LEXUS RX270',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'CT200h',
    name: 'LEXUS CT200h',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'IS300h',
    name: 'LEXUS IS300h',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'GS250',
    name: 'LEXUS GS250',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'GS300h',
    name: 'LEXUS GS300h',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'GS350',
    name: 'LEXUS GS350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'GS450h',
    name: 'LEXUS GS450h',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'LS460L',
    name: 'LEXUS LS460L',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'NX300h',
    name: 'LEXUS NX300h',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'ES300h',
    name: 'LEXUS ES300h',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'RC300h',
    name: 'LEXUS RC300h',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'RC200t',
    name: 'LEXUS RC200t',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'GS200T',
    name: 'LEXUS GS200T',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'RX200t',
    name: 'LEXUS RX200t',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'NX200t',
    name: 'LEXUS NX200t',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'NX300',
    name: 'LEXUS NX300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'LS500',
    name: 'LEXUS LS500',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'LS500H',
    name: 'LEXUS LS500H',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'IS300',
    name: 'LEXUS IS300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'RC300',
    name: 'LEXUS RC300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'UX250',
    name: 'LEXUS UX250',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'LM300H',
    name: 'LEXUS LM300H',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'UX300',
    name: 'LEXUS UX300',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'LS350',
    name: 'LEXUS LS350',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'NX350H',
    name: 'LEXUS NX350H',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEXUS',
    model: 'RZ 450 E',
    name: 'LEXUS RZ 450 E',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SSANG YONG',
    model: 'MUSSO',
    name: 'SSANG YONG MUSSO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SSANG YONG',
    model: 'E23',
    name: 'SSANG YONG E23',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SSANG YONG',
    model: 'REXTON',
    name: 'SSANG YONG REXTON',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SSANG YONG',
    model: 'ACTYON',
    name: 'SSANG YONG ACTYON',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SSANG YONG',
    model: 'CHAIRMAN',
    name: 'SSANG YONG CHAIRMAN',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SSANG YONG',
    model: 'KYRON',
    name: 'SSANG YONG KYRON',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SSANG YONG',
    model: 'KORANDO',
    name: 'SSANG YONG KORANDO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'JEEP',
    model: 'WILLY',
    name: 'JEEP WILLY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'DATSUN',
    model: 'BLUEBIRD',
    name: 'DATSUN BLUEBIRD',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MG',
    model: 'ZS',
    name: 'MG ZS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MG',
    model: '3',
    name: 'MG 3',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MG',
    model: '5',
    name: 'MG 5',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MG',
    model: 'MAXUS',
    name: 'MG MAXUS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MG',
    model: 'EP',
    name: 'MG EP',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MG',
    model: '4',
    name: 'MG 4',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MG',
    model: 'ES',
    name: 'MG ES',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SKODA',
    model: 'OCTAVIA',
    name: 'SKODA OCTAVIA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SKODA',
    model: 'SUPERB',
    name: 'SKODA SUPERB',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SKODA',
    model: 'FABIA',
    name: 'SKODA FABIA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'SKODA',
    model: 'YETI',
    name: 'SKODA YETI',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TUKTUK',
    model: 'PASSENGER',
    name: 'TUKTUK PASSENGER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TUKTUK',
    model: 'TUKTUK',
    name: 'TUKTUK TUKTUK',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TUKTUK',
    model: 'TUK TUK',
    name: 'TUKTUK TUK TUK',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TUKTUK',
    model: 'FORWADER',
    name: 'TUKTUK FORWADER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TATA',
    model: 'NANO',
    name: 'TATA NANO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PROTON',
    model: 'SAVVY',
    name: 'PROTON SAVVY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PROTON',
    model: 'GEN2',
    name: 'PROTON GEN2',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PROTON',
    model: 'NEO',
    name: 'PROTON NEO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PROTON',
    model: 'PERSONA',
    name: 'PROTON PERSONA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PROTON',
    model: 'EXORA',
    name: 'PROTON EXORA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PROTON',
    model: 'SAGA',
    name: 'PROTON SAGA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PROTON',
    model: 'PREVE',
    name: 'PROTON PREVE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'PROTON',
    model: 'SUPRIMA',
    name: 'PROTON SUPRIMA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'TR',
    model: 'TRANFORMER',
    name: 'TR TRANFORMER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'WULING',
    model: 'HONGTU',
    name: 'WULING HONGTU',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LONDON CAB',
    model: 'LONDIN',
    name: 'LONDON CAB LONDIN',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MINI',
    model: 'ONE',
    name: 'MINI ONE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MINI',
    model: 'COOPER',
    name: 'MINI COOPER',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MINI',
    model: 'CLUBVAN',
    name: 'MINI CLUBVAN',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MINI',
    model: 'JOHN COOPER WORKS',
    name: 'MINI JOHN COOPER WORKS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MINI',
    model: '50 MAYFAIR',
    name: 'MINI 50 MAYFAIR',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MINI',
    model: '50 CAMDEN',
    name: 'MINI 50 CAMDEN',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MINI',
    model: 'CLUBMAN',
    name: 'MINI CLUBMAN',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MINI',
    model: 'WORKS',
    name: 'MINI WORKS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUOKA',
    model: 'HIMIKO',
    name: 'MITSUOKA HIMIKO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUOKA',
    model: 'GALUE',
    name: 'MITSUOKA GALUE',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'MITSUOKA',
    model: 'VIEWT',
    name: 'MITSUOKA VIEWT',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'DFM',
    model: 'MINI MPV',
    name: 'DFM MINI MPV',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'DFM',
    model: 'MINI STAR',
    name: 'DFM MINI STAR',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'DFM',
    model: 'V27',
    name: 'DFM V27',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'DFM',
    model: 'GLORY',
    name: 'DFM GLORY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'ARIEL',
    model: 'NOMAD',
    name: 'ARIEL NOMAD',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NAZA',
    model: 'FORZA',
    name: 'NAZA FORZA',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHERY',
    model: 'QQ',
    name: 'CHERY QQ',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHERY',
    model: 'CROSS',
    name: 'CHERY CROSS',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHERY',
    model: 'TIGGO',
    name: 'CHERY TIGGO',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CHERY',
    model: 'A1',
    name: 'CHERY A1',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'DFSK',
    model: 'V27',
    name: 'DFSK V27',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'DFSK',
    model: 'GLORY',
    name: 'DFSK GLORY',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'CATERHAM',
    model: 'SUPER SPORT',
    name: 'CATERHAM SUPER SPORT',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BYD',
    model: 'E6',
    name: 'BYD E6',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BYD',
    model: 'T3',
    name: 'BYD T3',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BYD',
    model: 'ATT0 3',
    name: 'BYD ATT0 3',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BYD',
    model: 'DOPHIN',
    name: 'BYD DOPHIN',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'BYD',
    model: 'SEAL',
    name: 'BYD SEAL',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'DEBERTI',
    model: 'DEBERTI',
    name: 'DEBERTI DEBERTI',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'COMARTH',
    model: '2.0',
    name: 'COMARTH 2.0',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'JAC',
    model: 'JAC',
    name: 'JAC JAC',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'LEABON',
    model: 'LNE5000',
    name: 'LEABON LNE5000',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'ORA',
    model: 'GOOD CAT',
    name: 'ORA GOOD CAT',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NETA',
    model: 'V',
    name: 'NETA V',
    year_begin: '2000',
    year_end: '2024',
  },
  {
    brand: 'NEX',
    model: 'PICK UP',
    name: 'NEX PICK UP',
    year_begin: '2000',
    year_end: '2024',
  },
];