import React from 'react';
import { EasyScoreProps } from 'typings';
import styles from './index.less';
import { useIntl } from 'react-intl';

const EasyScore: React.FC<EasyScoreProps> = ({ theme, data }) => {
  const { formatMessage } = useIntl();
  return (
    <div
      className={styles.content}
      style={{ background: theme.bacgroundColor }}
    >
      <div className={styles.container}>
        <div className={styles.title} style={{ color: theme.titleColor }}>
          {formatMessage({ id: 'How easy is it to get car insurance?' })}
        </div>
        <div className={styles.cardContent}>
          {data.map((item: any, index: any) => {
            const { id, title, desc } = item || {};
            return (
              <div
                className={styles.cardContainer}
                style={{ background: theme.itemBackgroundColor }}
                key={index}
              >
                <div
                  className={styles.id}
                  style={{
                    background: theme.iconBgColor,
                    color: theme.iconTextColor,
                  }}
                >
                  <span>{id}</span>
                </div>
                <div
                  className={styles.cardText}
                  style={{ color: theme.itemTextColor }}
                >
                  {formatMessage({ id: title })}
                </div>
                <div
                  className={styles.desc}
                  style={{ color: theme.itemDescColor }}
                >
                  {formatMessage({ id: desc })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EasyScore;
