import { ComparisionTableProps } from 'typings';
import styles from './index.less';
import RowHeader from '../comparison-table-heading';
import ComparisionTableRow from '../comparision-table-row';
import { Button } from 'iglooform';
import line from '@/assets/LineIcon.svg';
import { useIntl } from 'react-intl';
import { trackEvent } from '@/utils/ga';
import { getGASource } from '@/utils/common';

const ComparisionTable: React.FC<ComparisionTableProps> = ({
  theme,
  data,
  source,
}) => {
  const { formatMessage, locale } = useIntl();
  return (
    <div
      className={styles.content}
      style={{ background: theme.backgroundColor }}
      id="comparisonTable"
    >
      <div className={styles.container}>
        <div className={styles.title} style={{ color: theme.titleColor }}>
          {formatMessage({
            id: 'Comparison of the different types of car insurance coverage',
          })}
        </div>
        <div className={styles.desc} style={{ color: theme.descColor }}>
          {formatMessage({
            id:
              'Below is a table of what damages each type of car insurance covers in the event of a car accident on the road, including repairs and medical bills.',
          })}
        </div>
      </div>
      <div className={styles.rowContentClass}>
        {data?.tableData?.map((item: any) => {
          const renderHeader = item?.rowType === 'heading';
          const renderContent = item?.rowType === 'content';

          return (
            <>
              {renderHeader && <RowHeader theme={theme} data={item} />}
              {renderContent && (
                <ComparisionTableRow theme={theme} data={item} />
              )}
            </>
          );
        })}
      </div>
      <div className={styles.note} style={{ color: theme.noteTextColor }}>
        {formatMessage({ id: '*When the third party is another vehicle' })}
      </div>
      <div
        className={styles.bottomText}
        style={{ color: theme.bottomTextColor }}
      >
        {formatMessage({
          id:
            'Still not sure which car insurance to get? Contact us on Line and our customer support will help you.',
        })}
      </div>
      <div className={styles.lineContainer}>
        <Button
          className={styles.lineButton}
          onClick={() => {
            isMobile
              ? (window.location.href = 'https://lin.ee/rmVOvfM')
              : window?.open('https://lin.ee/rmVOvfM');
            trackEvent('contact_us_button_click', {
              Site: getGASource(source, locale),
              buttonName: 'contact us',
            });
          }}
        >
          <div className={styles.textContent}>
            <img src={line} alt="Line" height={24} width={isMobile ? 26 : 27} />
            {formatMessage({ id: 'Contact us' })}
          </div>
        </Button>
      </div>
    </div>
  );
};

export default ComparisionTable;
