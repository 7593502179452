import React from 'react';
import { BenefitComponentProps } from 'typings';
import styles from './index.less';
import { useIntl } from 'react-intl';
import { EN_LOCALE } from '@/constants';
import { BENEFIT_SOURCE } from '@/constants/logo';

const IcarBenefit: React.FC<BenefitComponentProps> = ({
  theme,
  data,
  source,
}) => {
  const { formatMessage, locale } = useIntl();
  return (
    <div
      className={styles.content}
      style={{ background: theme.bacgroundColor }}
    >
      <div className={styles.container}>
        <div className={styles.title} style={{ color: theme.titleColor }}>
          {formatMessage({ id: 'Why choose' })}&nbsp;
          <span style={{ color: theme.iCarTextColor }}>
            {BENEFIT_SOURCE[source]}
          </span>{' '}
          x Igloo&nbsp;
          {locale === EN_LOCALE && formatMessage({ id: 'for car insurance?' })}
        </div>
        <div className={styles.contentData}>
          {data.map((item: any, index: any) => {
            return (
              <div
                className={styles.cardContainer}
                style={{ background: theme.itemBackgroundColor }}
                key={index}
              >
                <img
                  src={item.icon}
                  alt=""
                  width={isMobile ? '40' : '60'}
                  height={isMobile ? '40' : '60'}
                />
                <div
                  className={styles.cardTitle}
                  style={{ color: theme.iCarTextColor }}
                >
                  {formatMessage({ id: item.title })}
                </div>
                <div
                  className={styles.cardDesc}
                  style={{ color: theme.itemDescColor }}
                >
                  {index === 2 ? BENEFIT_SOURCE[source] : ''}
                  {formatMessage({ id: item.description })}
                  {index === 2 && locale === EN_LOCALE
                    ? BENEFIT_SOURCE[source] + '.'
                    : ''}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default IcarBenefit;
