import React from 'react';
import { InsurerLogoProps } from 'typings';
import styles from './index.less';
import { useIntl } from 'react-intl';

const InsurerLogoSection: React.FC<InsurerLogoProps> = ({ theme, data }) => {
  const { formatMessage } = useIntl();
  return (
    <div
      className={styles.content}
      style={{ background: theme.bacgroundColor }}
    >
      <div className={styles.container}>
        <div className={styles.title} style={{ color: theme.titleColor }}>
          {formatMessage({
            id: 'Igloo works with leading regional and local insurers',
          })}
        </div>
        <div className={styles.logoContainer}>
          {data.map((item: any, index: any) => (
            <img
              src={item}
              alt="partner"
              width={isMobile ? '56' : '80'}
              height={isMobile ? '56' : '80'}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default InsurerLogoSection;
